import { createAsyncThunk } from "@reduxjs/toolkit"
import { StageByCampaignIdInterface } from "../../slices/interface/stageByCampaignId.interface"
import { api } from "../../../utils/axios"
interface Props {
  campaignId: string
}
export const getStageByCampaignId = createAsyncThunk(
  "/voting-campaign/id",
  async (Args: Props, thunkAPI) => {
    try {
      const response = await api.get<StageByCampaignIdInterface>(`/voting-campaign-stages/voting-campaigns/${Args.campaignId}`)
      return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
