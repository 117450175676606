import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { DeleteSponsers } from "../../actions/sponsers/SponsersDelete.action";

interface InitialState {
  isFulfilled: boolean;
  isPending: boolean;
  isRejected: boolean;
}

const initialState: InitialState = {
  isFulfilled: false,
  isPending: false,
  isRejected: false,
};

export const delSponsers = createSlice({
  name: "@voting-dashboard/delete-sponsers",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isFulfilled = false;
      state.isRejected = false;
      state.isPending = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DeleteSponsers.pending, (state) => {
        state.isPending = true;
        state.isRejected = false;
        state.isFulfilled = false;
      })
      .addCase(DeleteSponsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.isPending = false;
        state.isFulfilled = true; // Always set to true on successful fulfillment
        state.isRejected = false;
      })
      .addCase(DeleteSponsers.rejected, (state) => {
        state.isPending = false;
        state.isFulfilled = false;
        state.isRejected = true;
      });
  },
});

export const { resetState } = delSponsers.actions;
export const deleteSponser = (state: RootStore) => state.delete_Sponser;
export default delSponsers.reducer;
