import { useFormik } from "formik"
import React, { useEffect, useRef } from "react"
import Navbar from "../../../../src/components/navbar/Navbar"
import PrevBtn from "../../../../src/ui/prevbutton/BackBtn"
import { UIInput } from "../../../../src/ui/uiinput/UIInput"
import { useAppDispatch } from "../../../utils/redux.utils"
import { useSelector } from "react-redux"
import { dataService } from "../../../utils/axios"
import { RootStore } from "../../../redux/store"
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../utils/toastify"
import { useNavigate, useParams } from "react-router-dom"
import { GetJudgeID } from "../../../../src/redux/actions/Judges/JudgeGetOne.action"
import { S3_URL } from "../../../constants/constants"
import { UpdateJudgeThunk } from "../../../../src/redux/actions/Judges/JudgeUpdate.action"
import { resetState } from "../../../../src/redux/slices/Judges/UpdateJudge.slices"
import { AddJudgeData } from "../utils/formdata"
import { EditValidationSchema, initialValue } from "../utils/JudgeValidation"


const EditJudges = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const didMount = useRef<boolean>(false)
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)

  const { data: AuthData } = useSelector((state: RootStore) => state.login)
  const {
    data: judgeData,
    isFulfilled,
    isPending,
    isRejected,
  } = useSelector((state: RootStore) => state.judge_by_id)

  const {
    isFulfilled: UpdateFullfiled,
    isLoading: UpdatePending,
    isRejected: UpdateRejected,
  } = useSelector((state: RootStore) => state.update_judge_by_campiagnID)

  
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      if (!id) {
        navigate(-1)
        return
      }
      dataService.setApiKey(x_api!)
      dataService.setToken(AuthData.token!)
      dispatch(GetJudgeID(id!))
    }
  }, [dispatch, id, navigate, x_api, AuthData.token])



  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: EditValidationSchema,
    onSubmit: async (values) => {
      console.log(values)
      const data: any = await AddJudgeData(values)
        apisendData(data)
    },
  })


  useEffect(() => {
    ;(UpdateFullfiled || UpdatePending || UpdateRejected) && dismissToast()

    if (UpdateRejected) {
      errorToast("Error Updating Judge ! Net Problem ??")
      dispatch(resetState())
    }
    if (UpdatePending) {
      loadingToast("Updating Judge")
      dispatch(resetState())
    }
    if (UpdateFullfiled) {
        successToast("Judge Updated Successfully");
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        dispatch(resetState());
      }

  }, [UpdateFullfiled, UpdatePending, UpdateRejected,  ,navigate])



  const apisendData =  async (formData: FormData) => {
    if (id) {
      await dispatch(UpdateJudgeThunk({ id, formData }))
    } else {
      console.error("ID is missing")
    }
  }



  useEffect(() => {
    if (isFulfilled && judgeData) {
      formik.setValues({
        name: judgeData.name || "",
        post: judgeData.post || "",
        existingimage: judgeData.image ? `${S3_URL}${judgeData.image}` : "",
        image: null || "",
        description: judgeData.description || "",
        votingCampaignId: judgeData.votingCampaignId || "",
      })
    }

    if (isRejected) {
      errorToast("Error fetching judge details!")
      dispatch(resetState())
    }

    if (isPending) {
      loadingToast("Fetching judge details...")
      dispatch(resetState())
    }

    return () => {
      dismissToast()
    }
  }, [isFulfilled, isPending, isRejected, judgeData])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Edit Judges" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="input">
              <UIInput
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                label="Name"
                type="text"
                placeholder="Enter Judge Name"
                formikError={formik}
              />
            </div>

            <div className="input">
              <UIInput
                value={formik.values.post}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="post"
                label="Judge Post"
                type="text"
                placeholder="Enter Judge Post"
                formikError={formik}
              />
            </div>

            <div className="input border border-red-500 px-3 rounded-xl">
              <label className="label">Image</label>
              <input
                name="image"
                type="file"
                onChange={(event: any) => {
                  formik.setFieldValue("image", event.currentTarget.files[0])
                }}
                onBlur={formik.handleBlur}
                accept="image/*"
                className="block w-full text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:font-semibold
                file:bg-[#0b005a]/80 file:text-white
                hover:file:bg-[#0b005a]"
              />
              {formik.values.image && formik.values.image instanceof File ? (
                <div className="image-preview mt-6">
                  <img
                    src={URL.createObjectURL(formik.values.image)}
                    width={200}
                    alt="New Preview"
                  />
                </div>
              ) : formik.values.existingimage ? (
                <div className="image-preview mt-6">
                  <img
                    src={formik.values.existingimage}
                    width={200}
                    alt="Existing Judge"
                  />
                </div>
              ) : null}
              {formik.errors.image && formik.touched.image && (
                <div className="error mt-2 text-red-600 text-base">
                  <>{formik.errors.image} *</>
                </div>
              )}
            </div>

            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={formik.values.description}
                name="description"
                placeholder="Enter your description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.description && formik.touched.description && (
                <div className="error mt-2 text-red-600 text-base">
                  {formik.errors.description} *
                </div>
              )}
            </div>

            <div className="btn-wrapper flex items-center justify-center">
              <button
                type="submit"
                className="py-2 px-36 text-white bg-black btn btn-submit rounded-full duration-200"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditJudges
