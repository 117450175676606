import { createAsyncThunk } from "@reduxjs/toolkit"
import { VotingCampaighCreationState } from "../../../pages/votingCampaign/add/AddEntry"
import { api } from "../../../utils/axios";
import { CreateVCampaignResponse } from "../../slices/interface/createVCampaign.interface";
export interface PropsData {
  data: VotingCampaighCreationState
}
export const createVCampaign = createAsyncThunk(
  "voting-campaign/create",
  async (data: VotingCampaighCreationState) => {
    const response = await api.post<CreateVCampaignResponse>("/voting-campaign", data)
    return response.data
  },
)
