import React, { useState, useEffect } from "react"
import Navbar from "../../components/navbar/Navbar"
import { voteData } from "../../constants/constants"
import PrevBtn from "../../ui/prevbutton/BackBtn"
import { CSVDownloadButton } from "../../ui/csv-download-button/csvDownloadButton"
import FilterVote from "./Filter/FilterVote"
import { VoteInterface } from "@/src/redux/slices/interface/vote.interface"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import {loginData} from "../../redux/slices/login/login.slices"
import { api, dataService } from "../../utils/axios"
import { GetAllVoteData } from "../../../src/redux/actions/vote/VoteData.actions"
import { VoteData } from "../../../src/redux/slices/vote/allvote.slices"

export const Votes = () => {
  const [voteValue, setVoteValue] = useState<any>([])
  const [filteredVoteValue, setFilteredVoteValue] = useState<VoteInterface[]>([])
  const [search, setSearch] = useState<string>("")
  const loginInfo = TypedSelector(loginData)
  const xapiKey = TypedSelector(xApiKey)
  const allvoteData =TypedSelector(VoteData);
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (loginInfo.data.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo.data, xapiKey])
 
  useEffect(() => {
    dispatch(GetAllVoteData())
  }, [])

  useEffect(()=>{
    if(filteredVoteValue.length>0){
      setVoteValue(filteredVoteValue)
    }else{setVoteValue(allvoteData.all_vote_data.data)}
  },[filteredVoteValue,allvoteData.all_vote_data.data])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Votes" showBars />
          <PrevBtn />
          <FilterVote setFilteredVoteValue={setFilteredVoteValue} />
          <div className="table-wrapper">
            <table className="table-wrapper-table" border={0}>
              <thead>
                <tr>
                  <th>SN</th>
                  <th>method</th>
                  <th>Date Time</th>
                  <th>Votes</th>
                  {/* <th>Phone</th> */}
                  <th>Email</th>
                  <th>Campaign</th>
                  <th>Stage</th>
                  <th>candidate</th>
                  <th>coupon</th>
                  {/* <th>Actions</th> */}

                  {/* <th>SN</th>
                            {
                                Object.keys(campaignDets[0]).map((col, index) => {
                                    return (<th key={index}>{col}</th>)
                                })
                            }
                            <th>Action</th> */}
                </tr>
              </thead>

              <tbody className="table-body">
                {allvoteData.all_vote_data.data && allvoteData.all_vote_data.data.length !== 0 ? (
                  voteValue.map((data: any, index:any) => {
                    return (
                      <tr key={index}>
                        <td className="sn">{index + 1}</td>
                        <td>{data.method}</td>
                        <td>{data.inserted}</td>
                        <td>{data.votes}</td>
                        {/* <td>{data.phoneNumber}</td> */}
                        <td>{data.email ? data.email : "Guest User"}</td>
                        <td>{data.votingCampaignStage?.votingCampaign?.title}</td>
                        <td>{data.votingCampaignStage?.title}</td>
                        <td>{data.candidate.name}</td>
                        <td>{data?.coupon?.name}</td>
                        {/* <td className="action"> */}
                        {/*   <button className="bttn delete-btn">Delete</button> */}
                        {/* </td> */}
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={12} className="notfound">
                      Data not found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <CSVDownloadButton data={voteData} title="vote" />
        </div>
      </div>
    </div>
  )
}
