import React from 'react';

type CampaignStatusDropdownProps = {
  current: number,
  setCurrent: (value: number) => void 
};

const CampaignStatusDropdown: React.FC<CampaignStatusDropdownProps> = ({ current, setCurrent }) => {
  return (
    <div className='w-fit ml-auto'>
      <select id="campaigns" name="camapaigns" value={current} 
       onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { setCurrent(parseInt(e.target.value)) }} 
       className='px-4 py-2 text-white bg-[var(--c-secondary)] rounded font-[500]'>
        <option value={1}>Upcomming</option>
        <option value={2}>Active</option>
        <option value={3}>Recently Finished</option>
      </select>
    </div>
  );
};

export default CampaignStatusDropdown;
