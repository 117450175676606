import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../../utils/axios"
interface Props {
    data: {
        candidateId: string
        votingCampaignStageId: string
    }
    xapi: string
    authToken: string
}
export const removeCandidate = createAsyncThunk("/removeCandidate", async (props: Props, thunkAPI) => {
    try {
        const response = await api.get("/voting-stage-candidates/remove-candidate", {
            headers: {
                "x-api-key": `Bearer ${props.xapi}`,
                "Authorization": `Bearer ${props.authToken}`,
            },
            params: JSON.stringify(props.data)
        })
        console.log(response.data)
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message)
    }
})