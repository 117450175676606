import { ChangeEvent, useEffect, useState } from "react"
import Navbar from "../../../../components/navbar/Navbar"
import { UIInput } from "../../../../ui/uiinput/UIInput"
import UIButton from "../../../../ui/uibutton/UIButton"
import PrevBtn from "../../../../ui/prevbutton/BackBtn"
import { useNavigate, useParams } from "react-router-dom"
import { TypedSelector, useAppDispatch } from "../../../../utils/redux.utils"
import { loginData } from "../../../../redux/slices/login/login.slices"
import { xApiKey } from "../../../../redux/slices/XapiKey/xApiKey.slice"
import { convertIsoToNormalDateTime, convertToIsoDateTime } from "../../../../utils/helper"
import { CreateCampaignStage } from "../../../../utils/zod/campaignstage/campaignStage.schema"
import { errorToast } from "../../../../utils/toastify"
import { CreateCampaignStageInt, NewState } from "../../../../redux/slices/interface/campaignStage.interface"
import { createVCampaignStage } from "../../../../redux/actions/votingCampaign/stages/createCampaignStage.action"
import { resetVSCreates, votingCampaignStage } from "../../../../redux/slices/votingCampaign/stage/createVCStage.slice"
import { toast } from "react-toastify"
import { votingCampaignDatas } from "../../../../redux/slices/votingCampaign/votingCamp.slice"
import { RangeDatePicker } from "../../../../components/date-picker/DatePicker"
import { dataService } from "../../../../utils/axios"
import { ImageValidation } from "../../add/AddEntry"


export const VCSAddEntry = () => {
  const navigate = useNavigate()
  const loginInfo = TypedSelector(loginData)
  const created = TypedSelector(votingCampaignStage)
  const votingData = TypedSelector(votingCampaignDatas)
  const xApi = TypedSelector(xApiKey)
  const { id } = useParams()
  const filteredData = votingData?.data?.data?.rows?.filter((current) => current.id === id)
  const dispatch = useAppDispatch()
  const [state, setState] = useState<CreateCampaignStageInt>({
    title: "",
    startDateTime: convertIsoToNormalDateTime(filteredData?.at(0)?.startDateTime ?? "") ?? "",
    endDateTime: convertIsoToNormalDateTime(filteredData?.at(0)?.endDateTime ?? "") ?? "",
    smsVotingLimit: "",
    emailVotingLimit: "",
    description: "",
    logo: null,
    banner: null
  })
  const [isImageError, setIsImageError] = useState<ImageValidation>({ logo: false, banner: false })
  const handelchange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      const files = e.target.files
      if (files && files.length > 0) {
        setState((prev) => ({ ...prev, [name]: files[0] }))
        return
      }
    }
    setState((prev) => ({ ...prev, [name]: value }))
  }
  const submithandler = (e: any) => {
    e.preventDefault()
    if (isImageError.logo || isImageError.banner) {
      errorToast("Logo and Banner should be less than 1mb")
      return
    }
    const validateStage = CreateCampaignStage.safeParse({
      ...state,
      startDateTime: convertToIsoDateTime(state.startDateTime),
      endDateTime: convertToIsoDateTime(state.endDateTime),
      votingCampaignId: id as string,
    })
    if (validateStage.error) {
      errorToast(validateStage.error.issues.at(0)?.message as string)
    }
    if (validateStage.success) {
      const newState: NewState = {
        ...state,
        startDateTime: convertToIsoDateTime(state.startDateTime),
        endDateTime: convertToIsoDateTime(state.endDateTime),
        votingCampaignId: id as string
      }
      dispatch(createVCampaignStage({ data: newState }))
    }
  }
  useEffect(() => {
    if (loginInfo.data.token && xApi.x_api) {
      dataService.setApiKey(xApi.x_api)
      dataService.setToken(loginInfo.data.token)
      dataService.setContentType("multipart/form-data")
    }
  }, [loginInfo, xApi])

  useEffect(() => {
    const logoSize = state.logo?.size
    const bannerSize = state.banner?.size
    if (logoSize)
      setIsImageError((prev) => ({ ...prev, logo: ((logoSize / (1000 * 1000))! > 1) }))
    if (bannerSize)
      setIsImageError((prev) => ({ ...prev, banner: ((bannerSize / (1000 * 1000))! > 1) }))
  }, [state.logo, state.banner])
  useEffect(() => {
    // handle stage added here
    if (created.data?.success) {
      toast.success("Stage created")
      navigate(-1)
      dispatch(resetVSCreates())
    }
    if (created.isError) {
      errorToast("something went wrong")
      dispatch(resetVSCreates())
    }
  }, [created])
  const handleStartDate = (e: string) => {
    setState((prev) => ({ ...prev, startDateTime: convertToIsoDateTime(e) }))
  }
  const handleEndDate = (e: string) => {
    setState((prev) => ({ ...prev, endDateTime: convertToIsoDateTime(e) }))
  }
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Add Entry - Voting Campaign stage" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <form className="form">
            <div className="input">
              <UIInput
                value={state.title}
                onChange={handelchange}
                name="title"
                label="Title"
                type="text"
              />
            </div>
            <div className="input">
              <UIInput
                value={id}
                onChange={handelchange}
                name="votingcampaignid"
                label="Voting Campaign ID"
                type="text"
                disabled={true}
              />
            </div>
            <div className={`input ${isImageError.logo && "border border-red-500 px-3 rounded-xl py-2"}`}>
              <label className="label">Logo</label>
              <input
                name="logo"
                type="file"
                onChange={handelchange}
                accept="image/*"
              />
            </div>
            <div className={`input ${isImageError.banner && "border border-red-500 px-3 rounded-xl py-2 "} `}>
              <label className="label">Banner</label>
              <input
                name="banner"
                type="file"
                onChange={handelchange}
                accept="image/*"
              />
            </div>
            <div className="input">
              <label className="inputfield-label">StartDateTime</label>
              <RangeDatePicker
                value={state.startDateTime}
                minDate={convertIsoToNormalDateTime(filteredData?.at(0)?.startDateTime ?? "") ?? ""}
                maxDate={state?.endDateTime.split('T').at(0)}
                onChange={handleStartDate}
                name='startDateTime'
              />
            </div>
            <div className="input">
              <label className="inputfield-label">EndDateTime</label>
              <RangeDatePicker
                value={state.endDateTime}
                minDate={state?.startDateTime.split('T').at(0)}
                maxDate={convertIsoToNormalDateTime(filteredData?.at(0)?.endDateTime ?? "") ?? ""}
                onChange={handleEndDate}
                name='endDateTime'
              />
            </div>
            <div className="input">
              <UIInput
                value={state.smsVotingLimit}
                onChange={handelchange}
                name="smsVotingLimit"
                label="SMS Voiting Limit"
                type="number"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.emailVotingLimit}
                onChange={handelchange}
                name="emailVotingLimit"
                label="Email Voiting Limit"
                type="number"
              />
            </div>
            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={state.description}
                name="description"
                onChange={handelchange}
              ></textarea>
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button className={`${created.isLoading ? "bg-[#2c2375]" : "bg-[#0b005a]"}  py-2 px-36 text-white  btn btn-submit rounded-full duration-200`}
                disabled={created.isLoading}
                onClick={submithandler}
              >
                {!created.isLoading ? "submit" : "Creating stage..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
