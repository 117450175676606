import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { dataService } from "../../../utils/axios";

// Define the type for the parameters
interface UpdateJudgeParams {
  id: any ;
  formData: FormData;
}

export const UpdateJudgeThunk = createAsyncThunk(
  '@voting-dashboard/updateJudge',
  async ({ id, formData }: UpdateJudgeParams, thunkAPI) => {
    try {
      const res = await dataService.putData(`/judge/${id}`, formData);
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);
