import { VOTING_USER_BASE_URI } from "../../../utils/config"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { FaqInterface } from "../../slices/interface/faq.interface"
import { dataService } from "../../../utils/axios"

// export const getFaqs = createAsyncThunk("x-api-key", async () => {
//     const response = await fetch(`${VOTING_USER_BASE_URI}/faq`, {
//         headers: {
//             "x-api-key": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb25JZCI6IjMzNGEyZWNmMzg4ODA5MDYiLCJpc3MiOiJ2b3RpbmdfYXBwbGljYXRpb25fb3JnYW5pemF0aW9uX21hbmFnZW1lbnQiLCJpYXQiOjE3MTQwMjI2NTcsImV4cCI6MTcyODYyMTMyMn0.ee1L2xWsAjtkaSXrq5EXEVz08kh0qk0QfzaDMfpQBBM`
//         }
//     })
//     const data: FaqInterface = await response.json()
//     return data
// })
export const getFaqs = createAsyncThunk(
  'voting-dashboard/dashboard',
  async ( thunkAPI) => {
    try {
      const res = await dataService.getData('/faq');
      return res.data;
    } catch (error: any) {
    //   return thunkAPI.rejectWithValue(error.message);
    }
  }
  
);