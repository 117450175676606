import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { dataService } from "../../../utils/axios";

// Define the type for the parameters
interface SponserJudgeParams {
  id: any ;
  formData: FormData;
}

export const SponserUpdateThunk = createAsyncThunk(
  '@voting-dashboard/updateSponser',
  async ({ id, formData }: SponserJudgeParams, thunkAPI) => {
    try {
      const res = await dataService.putData(`/sponsers/${id}`, formData);
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);
