import React from "react"
import Navbar from "../../components/navbar/Navbar"
import Performance from "../../components/performance/Performance"
import CampaignStatus from "../../components/campaign-status/campaign-status"

export const Reports : React.FC = () => {
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Report" showBars />

          <div className="report">
            <Performance title="campaign" />

            <div className="voting-trend">
              <h2 className="report-title">Voting Trends</h2>
            </div>

            <div className="status">
              <div className="div">
                <h2 className="report-title">Campaign Status</h2>
                
              </div>
              <CampaignStatus />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
