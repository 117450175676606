import { useFormik } from "formik"
import React from "react"
import PrevBtn from "../../../../src/ui/prevbutton/BackBtn"
import Navbar from "../../../../src/components/navbar/Navbar"
import { UIInput } from "../../../../src/ui/uiinput/UIInput"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { dataService } from "../../../utils/axios"
import store, { RootStore } from "../../../redux/store"
import { useSelector } from "react-redux"
import { TypedSelector, useAppDispatch } from "../../../utils/redux.utils"
import {
  SponsersInitialValue,
  ValidationSchema,
} from "../utils/SponsersValidation"
import { CreateSponser } from "../../../../src/redux/actions/sponsers/SponsersCreate.action"
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../utils/toastify"
import { resetState } from "../../../../src/redux/slices/Sponers/CreateSponsers.slices"

const AddSponsers = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const { data: AuthData } = useSelector((state: RootStore) => state.login)

  const { isFulfilled, isLoading, isRejected, errorMessage } = useSelector(
    (state: RootStore) => state.create_sponsers,
  )

  const formik = useFormik({
    initialValues: SponsersInitialValue,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      console.log(values)
      dataService.setApiKey(x_api!)
      dataService.setToken(AuthData.token!)
      dispatch(CreateSponser(values))
    },
  })

  useEffect(() => {
    ;(isFulfilled || isLoading || isRejected) && dismissToast()
    isRejected && errorToast("Error Adding Sponsers ! Net Problem ??")
    isLoading && loadingToast("Adding Sponsers")
    if (isFulfilled) {
      successToast("Sponsers Added Successfully")
      navigate(-1)
      dispatch(resetState())
    }
  }, [isFulfilled, isLoading, isRejected])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Add Entry - Sponser" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="input">
              <UIInput
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="title"
                label="Title"
                type="text"
                placeholder="Enter Sponser Title"
                formikError={formik}
              />
            </div>

            <div className="input">
              <UIInput
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="contact"
                label="Contact"
                type="tel"
                placeholder="Enter Contact Number"
                formikError={formik}
              />
            </div>
            <div className={`input "border border-red-500 px-3 rounded-xl"}`}>
              <label className="label">Image</label>
              <input
                name="logo"
                type="file"
                onChange={(event: any) => {
                  formik.setFieldValue("logo", event.currentTarget.files[0])
                }}
                onBlur={formik.handleBlur}
                accept="image/*"
                className="block w-full text-slate-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-full file:border-0
             file:font-semibold
            file:bg-[#0b005a]/80 file:text-white
            hover:file:bg-[#0b005a]
          "
              />
              {formik.values.logo && (
                <div className="image-preview mt-6">
                  <img
                    src={URL.createObjectURL(formik.values.logo as File)}
                    width={200}
                    alt=""
                  />
                </div>
              )}

              {formik.errors.logo && formik.touched.logo && (
                <div className="error mt-2 text-red-600 text-base">
                  {formik.errors.logo} *
                </div>
              )}
            </div>

            <div className="input textarea">
              <label className="label">Large Title</label>
              <textarea
                value={formik.values.largeTitle}
                name="largeTitle"
                placeholder="Enter your Large Title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.largeTitle && formik.touched.largeTitle && (
                <div className="error mt-2 text-red-600 text-base">
                  {formik.errors.largeTitle} *
                </div>
              )}
            </div>

            <div className="input">
              <UIInput
                value={formik.values.shortContent}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="shortContent"
                label="Short Content"
                type="text"
                placeholder="Enter Short Content"
                formikError={formik}
              />
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button
                type="submit"
                className={` py-2 px-36 text-white bg-black  btn btn-submit rounded-full duration-200`}
              >
                {isLoading ? "Creating Sponsers" : "Create Sponser"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddSponsers
