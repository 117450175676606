import PrevBtn from "../../../../src/ui/prevbutton/BackBtn"
import Navbar from "../../../../src/components/navbar/Navbar"
import { UIInput } from "../../../../src/ui/uiinput/UIInput"
import { useFormik } from "formik"
import { createinitialValue, ValidationSchema } from "../utils/JudgeValidation"
import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { dataService } from "../../../utils/axios"
import store, { RootStore } from "../../../redux/store"
import { useSelector } from "react-redux"
import { TypedSelector, useAppDispatch } from "../../../utils/redux.utils"

import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../utils/toastify"
import { CreateJudge } from "../../../../src/redux/actions/Judges/JudgesCreate.action"
import { resetState } from "../../../../src/redux/slices/Judges/CreateJudges.slices"

const AddJudges = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isFulfilled, isLoading, isRejected } = useSelector(
    (state: RootStore) => state.create_judge_by_campiagnID,
  )
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const { data: AuthData } = useSelector((state: RootStore) => state.login)

  const formik = useFormik({
    initialValues: createinitialValue,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values)
      JudgeCreate(values)
    },
  })

  useEffect(() => {
    ;(isFulfilled || isLoading || isRejected) && dismissToast()

    if (isRejected) {
      errorToast("Error Adding Judge ! Net Problem ??")
      dispatch(resetState())
    }
    if (isLoading) {
      loadingToast("Adding Judge")
      dispatch(resetState())
    }
    if (isFulfilled) {
      successToast("Judge Added Successfully")
      navigate(-1)
      dispatch(resetState())
    }
  }, [isFulfilled, isLoading, isRejected])


  const JudgeCreate = (values: any) => {
    dataService.setApiKey(x_api!)
    dataService.setToken(AuthData.token!)
    dispatch(CreateJudge(values))
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        formik.setFieldValue("votingCampaignId", location.state.id)
      } else {
        navigate("/votingCampaign")
      }
    } else {
      navigate("/votingCampaign")
    }

    dataService.setApiKey(x_api!)
    dataService.setToken(AuthData.token!)
  }, [location, x_api, AuthData, navigate])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Add Entry - Judges" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="input">
              <UIInput
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="name"
                label="Name"
                type="text"
                placeholder="Enter Judge Name"
                formikError={formik}
              />
            </div>

            <div className="input">
              <UIInput
                value={formik.values.post}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="post"
                label="Judge Post"
                type="text"
                placeholder="Enter Judge Post"
                formikError={formik}
              />
            </div>
            <div className={`input "border border-red-500 px-3 rounded-xl"}`}>
              <label className="label">Image</label>
              <input
                name="image"
                type="file"
                onChange={(event: any) => {
                  formik.setFieldValue("image", event.currentTarget.files[0])
                }}
                onBlur={formik.handleBlur}
                accept="image/*"
                className="block w-full text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
               file:font-semibold
              file:bg-[#0b005a]/80 file:text-white
              hover:file:bg-[#0b005a]
            "
              />
              {formik.values.image && (
                <div className="image-preview mt-6">
                  <img
                    src={URL.createObjectURL(formik.values.image as File)}
                    width={200}
                    alt=""
                  />
                </div>
              )}

              {formik.errors.image && formik.touched.image && (
                <div className="error mt-2 text-red-600 text-base">
                  {formik.errors.image} *
                </div>
              )}
            </div>

            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={formik.values.description}
                name="description"
                placeholder="Enter your description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.description && formik.touched.description && (
                <div className="error mt-2 text-red-600 text-base">
                  {formik.errors.description} *
                </div>
              )}
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button
                type="submit"
                className={` py-2 px-36 text-white bg-black  btn btn-submit rounded-full duration-200`}
              >
                {isLoading ? "Creating Judge" : "Create Judge"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddJudges
