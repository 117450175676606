import { useEffect, useRef } from "react"
import Navbar from "../../../components/navbar/Navbar"
import { CampaignStageTable } from "../../../components/campaignstage/CampaignStageTable"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootStore } from "../../../redux/store"
import { dataService } from "../../../utils/axios"
import { GetAllCandidateByVotingCampaignIdThunk } from "../../../redux/actions/candidates/getAllCandidatesByVotingCampaignId.actions"
import { useAppDispatch } from "../../../utils/redux.utils"
export const VotingCampaignStage = () => {
  let { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const didMount = useRef<boolean>(false);
  const { x_api } = useSelector((state: RootStore) => state.x_api_key);
  useEffect(() => {
    if (!didMount.current) {
      didMount.current = false
      if (!id) navigate('/votingcampaign');
      dataService.setApiKey(x_api!)
      dispatch(GetAllCandidateByVotingCampaignIdThunk(id!.split('&').at(0)!))
    }
  }, [id])


  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Voting Campaign Stage" showBars />
        </div>
        <CampaignStageTable />
      </div>
    </div>
  )
}
