import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../../utils/axios"

interface Props {
    xapi: string
}

export const getAllVotingCampaigns = createAsyncThunk("/getAllVotingCampaigns", async (props: Props, thunkAPI) => {
    try {
        const response = await api.get("/voting-campaign-stages", {
            headers: {
                "x-api-key": props.xapi,
            }
        })
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const getActiveVotingCampaigns = createAsyncThunk("/getActiveVotingCampaigns", async (props: Props, thunkAPI) => {
    try {
        const response = await api.get("/voting-campaign-stages/active", {
            headers: {
                "x-api-key": props.xapi,
            }
        })
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const getUpcomingVotingCampaigns = createAsyncThunk("/getUpcomingVotingCampaigns", async (props: Props, thunkAPI) => {
    try {
        const response = await api.get("/voting-campaign-stages/upcomming", {
            headers: {
                "x-api-key": props.xapi,
            }
        })
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
})
