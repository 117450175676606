import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignUpdateResponse, VotingCampaignInitial } from "../../interface/votingcampaign/updateVCampaign.interface";
import { updateCampaignStageLogo } from "../../../actions/votingCampaign/stages/updateLogo.action";
import { RootStore } from "../../../store";
const initialState: VotingCampaignInitial = {
    data: null,
    isLoading: false,
    isError: false
}
const updateCampaignStageLogoSlice = createSlice({
    name: "@updateCampaignLogo",
    initialState,
    reducers: {
        resetSCSUpdateLogo(state: VotingCampaignInitial) {
            state.data = null
            state.isError = false
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateCampaignStageLogo.pending, (state: VotingCampaignInitial) => {
            state.isLoading = true
            state.isError = false
        }).addCase(updateCampaignStageLogo.fulfilled, (state: VotingCampaignInitial, action: PayloadAction<CampaignUpdateResponse>) => {
            state.data = action.payload
            state.isLoading = false
        }).addCase(updateCampaignStageLogo.rejected, (state: VotingCampaignInitial) => {
            state.isError = true
            state.isLoading = false
        })
    }
});
export const { resetSCSUpdateLogo } = updateCampaignStageLogoSlice.actions
export const updateCampaignStageLogoState = (state: RootStore) => state.update_campaign_logo
export default updateCampaignStageLogoSlice.reducer