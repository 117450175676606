import { PayloadAction, createSlice} from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { GetJudgeID } from "../../actions/Judges/JudgeGetOne.action";
import { GetSponserByID } from "../../actions/sponsers/GetoneSponser.action";


interface Sponser{
    title: string;
    contact: string;
    largeTitle: string;
    shortContent: string;
    logo: string;
}
export interface initialSponserByIdData {
  data : Sponser | null
  isPending : boolean
  isFulfilled : boolean
  isRejected : boolean
}

const initialState : initialSponserByIdData = {
  data : null,
  isFulfilled : false,
  isPending : false,
  isRejected : false,
}

export const GetSponserSliceById = createSlice({
  name : '@voting-dashboard/GetSponserByID',
  initialState,
  reducers : {
    GetOneSponserResetState:(state)=>{
      state.isFulfilled = false
      state.isRejected = false
      state.isPending = false
      state.data = null
    }
  },
  extraReducers : (builder) =>{
    builder
      .addCase(GetSponserByID.pending ,(state) => {
        state.isPending = true
        state.isRejected = false  
        state.isFulfilled = false
      } )
      .addCase(GetSponserByID.fulfilled ,(state , action : PayloadAction<Sponser | undefined>) => {
        state.isPending = false
        state.isRejected = false
        state.isFulfilled = true

        state.data = action.payload ?? null
      } )
      .addCase(GetSponserByID.rejected , (state) => {
        state.isPending = false
        state.isFulfilled = false
        state.isRejected = true
      })
  }
})

export const GetSponserIdData = (state: RootStore) => state.sponser_by_id
export const  { GetOneSponserResetState } = GetSponserSliceById.actions

export default GetSponserSliceById.reducer



