import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataService } from "../../../utils/axios";

export const EditCandidatesProfileThunk = createAsyncThunk(
  'voting-dashboard/edit-canidates-profile-thunk',
  async ({ slug, data } : { slug : string , data : any}, thunkAPI : any) : Promise<boolean | undefined> => {
    try {
      const res = await dataService.patchFormData(`/candidates/profile-picture/${slug}`, data);
      return res.data; 
    } catch (error : any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
