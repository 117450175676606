import { createAsyncThunk } from "@reduxjs/toolkit"
import { FaqInterface } from "../../slices/interface/faq.interface"
import axios from "axios"
import { VOTING_USER_BASE_URI } from "../../../utils/config"
import { dataService } from "../../../utils/axios"
import { TypedSelector } from "../../../utils/redux.utils"
import { loginData } from "../../slices/login/login.slices"
import { xApiKey } from "../../slices/XapiKey/xApiKey.slice"
const d = {
    question: "Hey",
    answer: "Hey"
}
// const loginInfo = TypedSelector(loginData)
// const xapiKey = TypedSelector(xApiKey)
// console.log(loginInfo);

// export const createFaq = createAsyncThunk("x-api-key", async () => {
//     const aa = await axios.post(`${VOTING_USER_BASE_URI}/faq`, d, {
//         headers: {
//             "x-api-key": `Bearer ${xapiKey}`,
//             "Authorization": `Bearer ${loginInfo}`
//         }
//     })
//     console.log(aa.data)
//     const response = await fetch(`${VOTING_USER_BASE_URI}/faq`, {
//         method: "POST",
//     headers: {
//             "x-api-key": `Bearer ${xapiKey}`,
//             "Authorization": `Bearer ${loginInfo}`
//         },
//         body: JSON.stringify(d)
//     })
//     const data = await response.json()
//     console.log(data)
//     return data
// })
export const PostFAQData = createAsyncThunk(
  'voting-dashboard/dashboard',
  async ({ question, answer }: { question: string; answer: string }, thunkAPI) => {
    try {
      const res = await dataService.postData('/faq', { question, answer });
      return res.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
  
);
