import { fileToBase64 } from "../../../../src/utils/fileToBase64";

interface Data {
  title: string;
  contact: string;
  largeTitle: string;
  shortContent: string;
  logo: File | null | string;
  Existinglogo: string;
}

export const AddSponserData = async (data: Data) => {
  const formData = new FormData();
  formData.append("title", data.title);
  formData.append("contact", data.contact);
  formData.append("largeTitle", data.largeTitle);
  formData.append("shortContent", data.shortContent);

//   if (data.logo && typeof data.logo !== "string") {
//     // Convert image to base64 string
//     const base64Image = await fileToBase64(data.logo);
//     formData.append("logo", base64Image);
//   } else {
//     formData.append("logo", data.Existinglogo);
//   }


  return formData;
};