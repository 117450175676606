import { useState, useEffect, useCallback } from "react"
export const useDebounce = <T>(query: string, initial: T[] | undefined, milliSeconds: number) => {
    const [data, setData] = useState<T[]>([])
    const [isloading, setIsloading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const handleSearch = useCallback(async (query: string) => {
        try {
            if (initial) {
                const filterData: T[] = initial.filter((current: any) =>
                    current?.title.toLowerCase().split(" ").join("")
                        .includes(query.toLowerCase().split(" ").join("")))
                setData(filterData)
            }
        } catch (error) {
            setIsError(true)
        } finally {
            setIsloading(false)
        }
    }, [])
    useEffect(() => {
        setIsloading(true)
        const handler = setTimeout(() => {
            handleSearch(query)
        }, milliSeconds)
        return () => {
            clearTimeout(handler)
        }
    }, [query, milliSeconds, handleSearch])
    return { data, isloading, isError }
}