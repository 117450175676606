import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { CreateJudge } from "../../actions/Judges/JudgesCreate.action";
import { CreateSponser } from "../../actions/sponsers/SponsersCreate.action";

const initialState = {
  isLoading: false,
  isRejected: false,
  isFulfilled: false,
  errorMessage:""
}

export const CreateSponserSlice = createSlice({
  name: '@Voting-dashboard/createSponser',
  initialState,
  reducers : {
    resetState:(state)=>{
      state.isFulfilled = false
      state.isRejected = false
      state.isLoading = false
      state.errorMessage = ""
    },
  },
  extraReducers : (builder)=> {
      builder 
        .addCase(CreateSponser.pending , (state) => {
          state.isLoading = true
          state.isRejected = false
          state.errorMessage = ""
        })

        .addCase(CreateSponser.fulfilled , (state , action : PayloadAction<boolean | undefined>) =>{
            console.log(action.payload)
            state.isRejected = action.payload ? false : true
            state.isFulfilled = action.payload ?? false
            state.isLoading = false
        })

        .addCase(CreateSponser.rejected , (state,action: PayloadAction<any>) => {
          console.log('reject')
          state.isLoading = false
          state.isRejected = true
          state.isFulfilled = false
          state.errorMessage = action.payload

        })
  },
})

export const  { resetState } = CreateSponserSlice.actions
export const CreateJudgeData = (state: RootStore) => state.create_sponsers
export default CreateSponserSlice.reducer