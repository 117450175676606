import { createAsyncThunk } from "@reduxjs/toolkit"
import { DeleteResponse } from "../../../slices/interface/votingcampaign/stage/deleteVCStage.interface"
import { api } from "../../../../utils/axios"
export const deleteCStageById = createAsyncThunk(
    "deleteCampaign",
    async ({ id }: { id: string }, thunkAPI) => {
        try {
            const response = await api.delete<DeleteResponse>(
                `/voting-campaign-stages/${id}`)
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.message)
        }
    },
)
