import { createAsyncThunk } from "@reduxjs/toolkit"
import { UpdateVCStageInterface } from "../../../slices/interface/votingcampaign/stage/updateVCStage.interface"
import { api } from "../../../../utils/axios"
interface Props {
    id: string
    data: any
}
export const updateVCStage = createAsyncThunk(
    "deleteCampaign",
    async (props: Props, thunkAPI) => {
        try {
            const response = await api.put<UpdateVCStageInterface>(
                `/voting-campaign-stages/${props.id}`, props.data)
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message)
        }
    },
)