import React, { ElementRef, useEffect, useRef, useState } from "react"
import Navbar from "../../../../components/navbar/Navbar"
import { UIInput } from "../../../../ui/uiinput/UIInput"
import UIButton from "../../../../ui/uibutton/UIButton"
import PrevBtn from "../../../../ui/prevbutton/BackBtn"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import { TypedSelector, useAppDispatch } from "../../../../utils/redux.utils"
import { convertIsoToNormalDateTime, convertToIsoDateTime } from "../../../../utils/helper"
import { loginData } from "../../../../redux/slices/login/login.slices"
import { xApiKey } from "../../../../redux/slices/XapiKey/xApiKey.slice"
import { updateVCStage } from "../../../../redux/actions/votingCampaign/stages/updateVCStageById.action"
import { updateVotingCampaignStage } from "../../../../utils/zod/campaignstage/updateVCStage.schema"
import { StageRow } from "../../../../redux/slices/interface/stageByCampaignId.interface"
import { resetVCStage, updateCampaignStage } from "../../../../redux/slices/votingCampaign/stage/updateVCStage.slice"
import { FaCamera } from "react-icons/fa6"
import { S3_URL } from "../../../../constants/constants"
import { updateCampaignStageLogo } from "../../../../redux/actions/votingCampaign/stages/updateLogo.action"
import { errorToast, successToast } from '../../../../utils/toastify';
import { updateCampaignStageBanner } from "../../../../redux/actions/votingCampaign/stages/updateBanner.action"
import { resetSCSUpdateLogo, updateCampaignStageLogoState } from "../../../../redux/slices/votingCampaign/stage/updatelogo.slice"
import { RangeDatePicker } from "../../../../components/date-picker/DatePicker"
import { dataService } from "../../../../utils/axios"
import { resetDeleteState } from "../../../../redux/slices/votingCampaign/stage/deletVCStageByID.slice"
interface EditVCStage {
  title: string
  description: string
  startDateTime: string
  endDateTime: string
  smsVotingLimit: string
  emailVotingLimit: string
  votingCampaignId: string
}
export const VCSEditEntry = () => {
  const { id, "*": wildcard } = useParams()
  const campaignId = wildcard?.split("/")[2]
  const location = useLocation()
  const campaignData: StageRow = location?.state?.current
  const [state, setstate] = useState<EditVCStage>({
    title: campaignData.title || "",
    description: campaignData.description || "",
    startDateTime: convertIsoToNormalDateTime(campaignData.startDateTime) ?? "",
    endDateTime: convertIsoToNormalDateTime(campaignData.endDateTime) ?? "",
    smsVotingLimit: String(campaignData.smsVotingLimit) || "",
    emailVotingLimit: String(campaignData.emailVotingLimit) || "",
    votingCampaignId: campaignId as string,
  })
  const navigation = useNavigate()
  const fileRef = useRef<ElementRef<"input">>(null)
  const bannerRef = useRef<ElementRef<"input">>(null)
  const [file, setFile] = useState<any>(null)
  const [banner, setBanner] = useState<any>(null)
  const dispatch = useAppDispatch()
  const loginInfo = TypedSelector(loginData)
  const xapikey = TypedSelector(xApiKey)
  const logoState = TypedSelector(updateCampaignStageLogoState)
  const updateState = TypedSelector(updateCampaignStage)
  const handelchange = (e: any) => {
    const value = e.target.value
    setstate((prev) => ({ ...prev, [e.target.name]: value ?? "" }))
  }
  const submithandler = (e: any) => {
    e.preventDefault()
    try {
      const newState = updateVotingCampaignStage.safeParse({
        ...state,
        startDateTime: convertToIsoDateTime(state.startDateTime),
        endDateTime: convertToIsoDateTime(state.endDateTime)
      })
      if (newState.success) {
        const args = {
          id: id as string,
          data: newState.data,
        }
        dispatch(updateVCStage(args))
      } else
        console.log(newState.error)
    } catch (error) {
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if ((e.target.files[0].size / (1000 * 1000)) > 1) {
        errorToast("Logo should be less than 1mb")
      } else {
        setFile(URL.createObjectURL(e.target.files[0]))
      }
    }
  }
  const handleBannerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if ((e.target.files[0].size / (1000 * 1000)) > 1) {
        errorToast("Banner should be less than 1mb")
      } else {
        setBanner(URL.createObjectURL(e.target.files[0]))
      }
    }
  }
  const handleFileUpload = () => {
    fileRef.current?.click()
  }
  const handleBannerUpload = () => {
    bannerRef.current?.click()
  }
  const handleStartDate = (e: string) => {
    setstate((prev) => ({ ...prev, startDateTime: convertToIsoDateTime(e) }))
  }
  const handleEndDate = (e: string) => {
    setstate((prev) => ({ ...prev, endDateTime: convertToIsoDateTime(e) }))
  }
  useEffect(() => {
    if (updateState.data?.success) {
      dispatch(resetVCStage())
      successToast("Updated")
      dispatch(resetDeleteState())
      navigation(-1)
    }
  }, [updateState, campaignId, navigation, dispatch])
  useEffect(() => {
    if (fileRef.current?.files) {
      if (fileRef.current?.files[0]) {
        const pass = {
          id: campaignData.id,
          data: {
            logo: fileRef.current.files[0],
          },
        }
        dataService.setContentType("multipart/form-data")
        dispatch(updateCampaignStageLogo(pass))
      }
    }
  }, [file, campaignData, dispatch])
  useEffect(() => {
    if (bannerRef.current?.files) {
      if (bannerRef.current?.files[0]) {
        const pass = {
          id: campaignData.id,
          data: {
            banner: bannerRef.current.files[0],
          },
        }
        dataService.setContentType("multipart/form-data")
        dispatch(updateCampaignStageBanner(pass))
      }
    }
  }, [banner, campaignData, dispatch])
  useEffect(() => {
    if (logoState.data?.success) {
      dispatch(resetSCSUpdateLogo())
      // successToast("Logo Updated")
    }
    if (logoState.isError) {
      errorToast("Something went wrong")
    }
    dispatch(resetSCSUpdateLogo())
  }, [logoState, campaignId, dispatch, navigation])
  useEffect(() => {
    if (loginInfo.data.token && xapikey.x_api) {
      dataService.setApiKey(xapikey.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo, xapikey])
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Edit Entry - Voting Campaign stage" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <div className="z-30 h-auto relative mb-10 flex overflow-hidden">
            <FaCamera size={20} className="absolute -z-20 right-5 top-5 cursor-pointer text-pink-500 "
              onClick={handleBannerUpload}
            />
            <input type="file" ref={bannerRef} className="hidden" onChange={handleBannerChange} />
            <img src={banner ?? S3_URL.concat(campaignData.banner)} alt="bannerimage" className="absolute w-full -z-30" />
            <div className="overflow-hidden  h-48 w-48 flex items-center justify-center rounded-full border-2 border-gray-300 ">
              <input type="file" ref={fileRef} className="hidden" onChange={handleChange} />
              <FaCamera size={20} className="absolute left-40 bottom-6 cursor-pointer text-pink-500 "
                onClick={handleFileUpload}
              />
              <img src={file ?? S3_URL.concat(campaignData.logo)} alt="Logo" className="object-cover h-48 w-48" />
            </div>
          </div>
          <form className="form">
            <div className="input">
              <UIInput
                value={state.title}
                onChange={handelchange}
                name="title"
                label="Title"
                type="text"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.votingCampaignId}
                onChange={handelchange}
                name="votingCampaignId"
                label="Voting Campaign ID"
                type="text"
                disabled={true}
              />
            </div>
            <div className="input">
              <label className="inputfield-label">StartDateTime</label>
              <RangeDatePicker
                value={state.startDateTime}
                minDate={convertIsoToNormalDateTime(campaignData.startDateTime) ?? ""}
                maxDate={state?.endDateTime.split('T').at(0)}
                onChange={handleStartDate}
                name='startDateTime'
              />
            </div>
            <div className="input">
              <label className="inputfield-label">EndDateTime</label>
              <RangeDatePicker
                value={state.endDateTime}
                minDate={state?.startDateTime.split('T').at(0)}
                maxDate={convertIsoToNormalDateTime(campaignData.endDateTime) ?? ""}
                onChange={handleEndDate}
                name='endDateTime'
              />
            </div>
            <div className="input">
              <UIInput
                value={state.smsVotingLimit}
                onChange={handelchange}
                name="smsVotingLimit"
                label="SMS Voiting Limit"
                type="number"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.emailVotingLimit}
                onChange={handelchange}
                name="emailVotingLimit"
                label="Email Voiting Limit"
                type="number"
              />
            </div>
            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={state.description}
                name="description"
                onChange={handelchange}
              ></textarea>
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button className={`${updateState.isLoading ? "bg-[#2c2375]" : "bg-[#0b005a]"}  py-2 px-36 text-white  btn btn-submit rounded-full duration-200`}
                disabled={updateState.isLoading}
                onClick={submithandler}
              >
                {(!updateState.isLoading && !updateState.isError) ? "Save" : "Updating..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
