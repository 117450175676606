import React, { useEffect, useState } from "react"
import Navbar from "../../components/navbar/Navbar"
import { UIInput } from "../../ui/uiinput/UIInput"
import UIButton from "../../ui/uibutton/UIButton"
import { FaRegQuestionCircle } from "react-icons/fa"
import { MdDeleteOutline } from "react-icons/md"
import { BiSolidEdit } from "react-icons/bi"
import { GoTriangleRight } from "react-icons/go"
import { useAppDispatch, TypedSelector } from "../../utils/redux.utils"
import { loginData } from "../../redux/slices/login/login.slices"
import { getFaqs } from "../../redux/actions/faq/faq.action"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { dataService } from "../../utils/axios"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import { DeletefaqsThunk } from "../../redux/actions/faq/deletefaqs.actions"
import { EditfaqsThunk } from "../../redux/actions/faq/editfaqs.actions"
import { PostFAQData } from "../../redux/actions/faq/addFa.action"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

interface MessageInterface {
  question: string
  answer: string
}

const FAQ: React.FC = () => {
  const loginInfo = TypedSelector(loginData)
  const xapiKey = TypedSelector(xApiKey)
  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const faq = useSelector((state: RootStore) => state.faq)
  const { data: faqData } = faq

  const [faqsFetched, setFaqsFetched] = useState(false)
  const [state, setState] = useState<MessageInterface>({
    question: "",
    answer: "",
  })
  const [openqa, setOpenqa] = useState<number | null>(null)
  const [editMode, setEditMode] = useState<number | null>(null)
  const [editState, setEditState] = useState<MessageInterface>({
    question: "",
    answer: "",
  })

  useEffect(() => {
    if (loginInfo.data.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo.data, xapiKey])

  useEffect(() => {
    if (x_api) {
      dispatch(getFaqs()).then(() => setFaqsFetched(true))
    }
  }, [dispatch, x_api])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const submitHandler = () => {
    dispatch(PostFAQData({ question: state.question, answer: state.answer }))
      .then(() => {
        toast.success("FAQ added successfully!")
        dispatch(getFaqs()).then(() => setFaqsFetched(true))
        setState({ question: "", answer: "" })
      })
      .catch(() => {
        toast.error("Failed to add FAQ.")
      })
  }

  const toggleqa = (index: number) => {
    setOpenqa((prevIndex) => (prevIndex === index ? null : index))
  }

  const toggleEditMode = (index: number, question: string, answer: string) => {
    if (editMode === index) {
      setEditMode(null)
      setEditState({ question: "", answer: "" })
    } else {
      setEditMode(index)
      setEditState({ question, answer })
    }
  }

  const handleEditChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target
    setEditState((prev) => ({ ...prev, [name]: value }))
  }

  const handleEditSubmit = (id: string) => {
    dispatch(
      EditfaqsThunk({
        id,
        question: editState.question,
        answer: editState.answer,
      }),
    )
      .then(() => {
        toast.success("FAQ updated successfully!")
        dispatch(getFaqs()).then(() => setFaqsFetched(true))
        setEditMode(null)
      })
      .catch(() => {
        toast.error("Failed to update FAQ.")
      })
  }

  const handleDelete = (id: string) => {
    dispatch(DeletefaqsThunk(id))
      .then(() => {
        toast.success("FAQ deleted successfully!")
        dispatch(getFaqs()).then(() => setFaqsFetched(true))
      })
      .catch(() => {
        toast.error("Failed to delete FAQ.")
      })
  }

  const faqArray = faqData ? Object.values(faqData) : []

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="FAQ" showBars />
        </div>
        <div className="faq-page">
          <div className="add-qa">
            <p className="title">Add or delete FAQs</p>
            <div className="add-qa-input flex items-center justify-center">
              <UIInput
                name="question"
                onChange={handleChange}
                type="text"
                value={state.question}
                label="Question"
                classname="w-full"
                isRequired={true}
                placeholder="Enter your Question here"
              />
              <UIInput
                name="answer"
                onChange={handleChange}
                type="text"
                value={state.answer}
                label="Answer"
                classname="w-full"
                isRequired
                placeholder="Enter your Answer here"
              />
              <UIButton
                onClick={submitHandler}
                label="Add"
                style={{ color: "white" }}
                className="btn-primary px-20"
              />
            </div>
          </div>
          {faqsFetched ? (
            faqArray.length > 0 ? (
              <div className="display-faq">
                <ul className="faq-lists">
                  {faqArray.map((item: any, index: number) => (
                    <li key={item.id} onClick={() => toggleqa(index)}>
                      {editMode === index ? (
                        <div className="edit-qa space-y-4 px-4">
                          <UIInput
                            name="question"
                            onChange={handleEditChange}
                            type="text"
                            value={editState.question}
                            label="Edit Question"
                            classname="w-full"
                            isRequired={true}
                            placeholder="Edit Question"
                          />

                          <textarea
                            name="answer"
                            onChange={handleEditChange}
                            value={editState.answer}
                            className="w-full p-2 border rounded"
                            placeholder="Edit Answer"
                            rows={5}
                            required
                          />
                          <div className="flex space-x-4">
                            <UIButton
                              onClick={() => handleEditSubmit(item.id)}
                              label="Save"
                              style={{ color: "white" }}
                              className="btn-primary"
                            />
                            <UIButton
                              onClick={() => setEditMode(null)}
                              label="Cancel"
                              style={{ color: "white" }}
                              className="btn-primary"
                            />
                          </div>
                        </div>
                      ) : (
                        <span className="question-wrapper flex justify-between items-center">
                          <p className="question flex items-center">
                            <span className="mr-2">
                              <GoTriangleRight
                                style={{
                                  rotate: openqa === index ? "90deg" : "0deg",
                                }}
                              />
                            </span>
                            <span className="mr-2">
                              <FaRegQuestionCircle />
                            </span>
                            {item.question}
                          </p>
                          <div className="action flex space-x-2">
                            <BiSolidEdit
                              className="edit-icon cursor-pointer"
                              onClick={() =>
                                toggleEditMode(
                                  index,
                                  item.question,
                                  item.answer,
                                )
                              }
                            />
                            <MdDeleteOutline
                              className="del-icon cursor-pointer"
                              onClick={() => handleDelete(item.id)}
                            />
                          </div>
                        </span>
                      )}
                      <span
                        className="answer-wrapper"
                        style={{ display: index === openqa ? "block" : "none" }}
                      >
                        <p className="answer">{item.answer}</p>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="w-full h-1/2 flex items-center justify-center">
                <h1 className="text-2xl">No FAQs available</h1>
              </div>
            )
          ) : (
            <div className="w-full h-1/2 flex items-center justify-center">
              <h1 className="text-2xl">Fetching FAQs...</h1>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default FAQ
