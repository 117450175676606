import { CSVLink } from "react-csv";
import { FaDownload } from "react-icons/fa6";

export const CSVDownloadButton: React.FC<{ title?: string, data?: any }> = ({title, data} ) => {
  // Add a null check for data

  const headers = Object.keys(data[0]);
  const csvData = data.map((entry: any) => headers.map(header => entry[header]));
  csvData.unshift(headers);
  const csvString = csvData.map((row: any) => row.join(",")).join("\n");

  return (
    <CSVLink data={csvData} filename={`${title}.csv`} 
      className="text-white font-[500] w-fit bg-[var(--c-secondary)] px-4 py-2 rounded-xl ml-auto flex gap-2 
      items-center justify-center hover:bg-[#fff] hover:text-[var(--c-secondary)] hover:border-[var(--c-secondary)] border-[1px] transition-ease duration-300">
      <FaDownload /> Download {title}
    </CSVLink>
  );
}
