import React from 'react';
import Navbar from "../../components/navbar/Navbar";
import TableTop from "../../components/tabletop/Tabletop";

const CampaignReports = () => {
  // Static data for demonstration purposes
  const staticData = [
    {
      campaignName: "Campaign 1",
      candidates: 5,
      votes: "200 SMS / 150 Coupon",
      stages: 3,
      dateTime: "2024-07-20 14:00",
    },
    {
      campaignName: "Campaign 2",
      candidates: 10,
      votes: "500 SMS / 300 Coupon",
      stages: 5,
      dateTime: "2024-07-21 09:00",
    },
    {
      campaignName: "Campaign 3",
      candidates: 8,
      votes: "350 SMS / 250 Coupon",
      stages: 4,
      dateTime: "2024-07-22 16:00",
    },
  ];

  return (
    <div className="framecontainer select-none">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Campaign Reports" showBars />
        </div>
        <div className="table-wrapper pt-12">
          <table className="table-wrapper-table rounded-lg overflow-hidden border" border={0}>
            <thead>
              <tr className="tracking-wide">
                <th>Campaign Name</th>
                <th>Candidates</th>
                <th>Votes (SMS/Coupon)</th>
                <th>Stages</th>
                <th>Date Time</th>

              </tr>
            </thead>
            <tbody className="table-body">
              {staticData.map((campaign, index) => (
                <tr key={index} className="cursor-pointer hover:bg-[#0b005a19] duration-150">
                  <td>{campaign.campaignName}</td>
                  <td>{campaign.candidates}</td>
                  <td>{campaign.votes}</td>
                  <td>{campaign.stages}</td>
                  <td>{campaign.dateTime}</td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CampaignReports;
