import { mixed, object, string } from "yup";
import { InferType } from "yup";

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG'];
const MAX_FILE_SIZE = 50 * 1024; // 300KB

export const initialValue = {
  name: "",
  description: "",
  votingCampaignId: "",
  post: "",
  image: null as string | File | null,
  existingimage: " " || null,

   // Adjusted type
};

export const createinitialValue = {
  name: "",
  description: "",
  votingCampaignId: "",
  post: "",
  image: null as string | File | null,
};


export const ValidationSchema = object({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  votingCampaignId: string(),
  post: string().required("Post is required"),
  image: mixed()
    .required("Image is required")
    .test("fileFormat", "Only JPG, JPEG, and PNG files are allowed", (value: File | null |any) => {
      if (value) {
        return SUPPORTED_FORMATS.includes(value.type);
      }
      return true;
    })
    .test("fileSize", "File size must be less than 50kb", (value: File | null | any) => {
      if (value) {
        return value.size <= MAX_FILE_SIZE;
      }
      return true;
    }),
});

export const EditValidationSchema = object({
  name: string().required("Name is required"),
  description: string().required("Description is required"),
  votingCampaignId: string(),
  post: string().required("Post is required"),
  existingimage: string(),
  image: mixed()
  .test("fileFormat", "Only JPG, JPEG, and PNG files are allowed", (value: File | null |any) => {
    if (value) {
      return SUPPORTED_FORMATS.includes(value.type);
    }
    return true;
  })
  .test("fileSize", "File size must be less than 50KB", (value: File | null | any) => {
    if (value) {
      return value.size <= MAX_FILE_SIZE;
    }
    return true;
  }),
});
