import { createAsyncThunk } from "@reduxjs/toolkit";
import { OrganizationSettingsInterface, GetAllOrganizationSettingsInterface } from "../../slices/interface/organizationSetting.interface";
import { VOTING_USER_BASE_URI } from "../../../utils/config"

export const GetAllOrganizationSettingsThunk = createAsyncThunk("x-api-key", async () => {
  const response = await fetch(`${VOTING_USER_BASE_URI}/org-setting`, {
      headers: {
          "x-api-key": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb25JZCI6IjMzNGEyZWNmMzg4ODA5MDYiLCJpc3MiOiJ2b3RpbmdfYXBwbGljYXRpb25fb3JnYW5pemF0aW9uX21hbmFnZW1lbnQiLCJpYXQiOjE3MTQwMjI2NTcsImV4cCI6MTcyODYyMTMyMn0.ee1L2xWsAjtkaSXrq5EXEVz08kh0qk0QfzaDMfpQBBM`
      }
  })
  const data: any = await response.json()
  return data
})