import { createAsyncThunk } from "@reduxjs/toolkit"
import { CampaignUpdateResponse } from "../../slices/interface/votingcampaign/updateVCampaign.interface"
import { api } from "../../../utils/axios"
interface DataProps {
    id: string
    data: {
        title: string
        description: string
        startDateTime: string
        endDateTime: string
        timeZone: string
    }
}
export const updateCampaignById = createAsyncThunk(
    "editCampaign",
    async (data: DataProps, thunkAPI) => {
        try {
            const response = await api.put<CampaignUpdateResponse>(
                `/voting-campaign/${data.id}`, data.data, {
            },
            )
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message)
        }
    },
)