import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataService } from "../../../utils/axios";
import { GetCoupanByCampaignIdResponse } from "../../slices/interface/coupon.interface";
export const CreateCoupon = createAsyncThunk(
  '@voting-dashboard/createCouponThunk',
  async (formdata: any, thunkApi) => {
    try {
      const res : GetCoupanByCampaignIdResponse = await dataService.postData('/coupons', formdata)
      return res.success
    } catch (e: any) {
      console.log(e)
      thunkApi.rejectWithValue(e)
    }
  }
)