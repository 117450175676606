import React, { useEffect, useState } from "react"
import { UIInput } from "../../ui/uiinput/UIInput"
import Navbar from "../../components/navbar/Navbar"
import UIButton from "../../ui/uibutton/UIButton"
import PrevBtn from "../../ui/prevbutton/BackBtn"
import { useSelector } from "react-redux"
import { RootStore } from "@/src/redux/store"
import { GetAllOrganizationSettingsThunk } from "../../redux/actions/organization/getAllOrganizationSetting.action"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { GetAllOrganizationSettingsData } from "../../redux/slices/organization/getAllOrganizationSetting.slice"
import { UpdateOrganizationSettingThunk } from "../../redux/actions/organization/updateOrganization.action"
import { resetState } from "../../redux/slices/organization/updateOrganization.slice"
import {
  successToast,
  errorToast,
  loadingToast,
  dismissToast,
} from "../../utils/toastify"

export const OrganizationSetting = () => {
  const [state, setState] = useState({
    orgName: "",
    orgEmail: "",
    contactNumber: "",
    location: "",
    copyright: "",
    dateFormat: "",
    defaultCurrency: "",
    defaultLanguage: "",
    timeZone: "",
    latestNewsUpdates: "",
    sponserTitle: "",
    sponserBody: "",
    contestantBody: "",
    competitionBody: "",
    knowUsTitle: "",
    knowUsBody: "",
    platformCharge: 0,
    footerLeftText: "",
    enableEmailNotification: false,
    registrationEmailVerification: false,
    facebookURL: "",
    twitterURL: "",
    linkedinURL: "",
    pinterestURL: "",
    instagramURL: "",
    tiktokURL: "",
    sponserImages: [] as string[],
  })

  const [toast, setToast] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const org = TypedSelector(GetAllOrganizationSettingsData)
  const { isFulfilled, isRejected, isLoading } = useSelector(
    (state: RootStore) => state.update_organization_setting,
  )

  useEffect(() => {
    if (x_api) {
      dispatch(GetAllOrganizationSettingsThunk())
    }
  }, [dispatch, x_api])

  useEffect(() => {
    if (org?.data && org.data.length > 0) {
      const response = org.data[0]
      setState({
        orgName: response.orgName,
        orgEmail: response.orgEmail,
        contactNumber: response.contactNumber,
        location: response.location,
        copyright: response.copyright,
        dateFormat: response.dateFormat,
        defaultCurrency: response.defaultCurrency,
        defaultLanguage: response.defaultLanguage,
        timeZone: response.timeZone,
        latestNewsUpdates: response.latestNewsUpdates,
        sponserTitle: response.sponserTitle,
        sponserBody: response.sponserBody,
        contestantBody: response.contestantBody,
        competitionBody: response.competitionBody,
        knowUsTitle: response.knowUsTitle,
        knowUsBody: response.knowUsBody,
        platformCharge: response.platformCharge,
        footerLeftText: response.footerLeftText,
        enableEmailNotification: response.enableEmailNotification,
        registrationEmailVerification: response.registrationEmailVerification,
        facebookURL: response.facebookURL,
        twitterURL: response.twitterURL,
        linkedinURL: response.linkedinURL,
        pinterestURL: response.pinterestURL,
        instagramURL: response.instagramURL,
        tiktokURL: response.tiktokURL,
        sponserImages: response.sponserImages || [],
      })
    }
  }, [org?.data])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file),
      )
      setState((prev) => ({
        ...prev,
        sponserImages: fileArray,
      }))
    }
  }

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target
    setState((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
            ? Number(value)
            : value,
    }))
  }

  const [res] = org.data ?? []
  const id = res?.id

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(UpdateOrganizationSettingThunk({ id, data: state }))
  }

  useEffect(() => {
    if (isLoading && !isRejected && !isFulfilled) {
      loadingToast("Updating Organization Settings...")
      setToast(true)
    } else if (isFulfilled && !isLoading && !isRejected) {
      toast && dismissToast()
      successToast("Successfully updated Organization Settings")
      setToast(false)
      dispatch(resetState())
    } else if (isRejected && !isLoading && !isFulfilled) {
      toast && dismissToast()
      errorToast("Error updating Organization Settings")
      setToast(false)
      dispatch(resetState())
    }
  }, [isFulfilled, isRejected, isLoading])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Add Entry - Organization Settings" showBars />
        </div>

        <PrevBtn />
        <div className="form-wrapper">
          <form className="form-org space-y-6" onSubmit={handleSubmit}>
            <div className=" flex grow gap-4">
              <UIInput
                value={state.orgName}
                onChange={handleChange}
                placeholder="Organization Name"
                name="orgName"
                label="Organization Name"
                type="text"
                isRequired
              />
              <UIInput
                value={state.orgEmail}
                onChange={handleChange}
                placeholder="Organization Email"
                name="orgEmail"
                label="Organization Email"
                type="email"
                isRequired
              />
            </div>
            <div className=" flex grow gap-4">
              <UIInput
                value={state.contactNumber}
                onChange={handleChange}
                placeholder="Contact Number"
                name="contactNumber"
                label="Contact Number"
                type="text"
                isRequired
              />
              <UIInput
                value={state.location}
                onChange={handleChange}
                placeholder="Location"
                name="location"
                label="Location"
                type="text"
                isRequired
              />
            </div>

            <div className=" flex grow gap-4">
              <UIInput
                value={state.copyright}
                onChange={handleChange}
                placeholder="Copyright"
                name="copyright"
                label="Copyright"
                type="text"
                isRequired
              />
              <UIInput
                value={state.dateFormat}
                onChange={handleChange}
                placeholder="Date Format"
                name="dateFormat"
                label="Date Format"
                type="text"
                isRequired
              />
            </div>

            <div className=" flex grow gap-4">
              <UIInput
                value={state.defaultCurrency}
                onChange={handleChange}
                placeholder="Default Currency"
                name="defaultCurrency"
                label="Default Currency"
                type="text"
                isRequired
              />
              <UIInput
                value={state.defaultLanguage}
                onChange={handleChange}
                placeholder="Default Language"
                name="defaultLanguage"
                label="Default Language"
                type="text"
                isRequired
              />
            </div>
            <div className=" flex grow gap-4">
              <UIInput
                value={state.timeZone}
                onChange={handleChange}
                placeholder="Time Zone"
                name="timeZone"
                label="Time Zone"
                type="text"
              />
              <UIInput
                value={state.latestNewsUpdates}
                onChange={handleChange}
                placeholder="Latest News Updates"
                name="latestNewsUpdates"
                label="Latest News Updates"
                type="text"
                isRequired
              />
            </div>
            <div className=" flex grow gap-4">
              <UIInput
                value={state.sponserTitle}
                onChange={handleChange}
                placeholder="Sponsor Title"
                name="sponserTitle"
                label="Sponsor Title"
                type="text"
                isRequired
              />
            </div>
            <div className="input textarea grow">
              <label className="label">Sponsor Body</label>
              <textarea
                value={state.sponserBody}
                name="sponserBody"
                placeholder="Enter sponser Body"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="input textarea grow">
              <label className="label">Contestant Body</label>
              <textarea
                value={state.contestantBody}
                name="contestantBody"
                placeholder="Enter contestant Body"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="input textarea grow">
              <label className="label">Competition Body</label>
              <textarea
                value={state.competitionBody}
                name="competitionBody"
                placeholder="Enter competition body"
                onChange={handleChange}
              ></textarea>
            </div>

            <div className=" flex grow gap-4">
              <UIInput
                value={state.knowUsTitle}
                onChange={handleChange}
                placeholder="Know Us Title"
                name="knowUsTitle"
                label="Know Us Title"
                type="text"
                isRequired
              />
            </div>
            <div className="input textarea">
              <label className="label">Know Us Body</label>
              <textarea
                value={state.knowUsBody}
                name="knowUsBody"
                placeholder="Enter Know Us Body"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className=" flex grow gap-4">
              <UIInput
                value={state.platformCharge}
                onChange={handleChange}
                placeholder="Platform Charge"
                name="platformCharge"
                label="Platform Charge"
                type="number"
              />
              <UIInput
                value={state.footerLeftText}
                onChange={handleChange}
                placeholder="Footer Left Text"
                name="footerLeftText"
                label="Footer Left Text"
                type="text"
                isRequired
              />
            </div>

            <div className=" flex grow gap-4">
              <label>Enable Email Notification</label>
              <input
                type="checkbox"
                name="enableEmailNotification"
                checked={state.enableEmailNotification}
                onChange={handleChange}
              />
              <label>Registration Email Verification</label>
              <input
                type="checkbox"
                name="registrationEmailVerification"
                checked={state.registrationEmailVerification}
                onChange={handleChange}
              />
            </div>

            <div className=" flex grow gap-4">
              <UIInput
                value={state.facebookURL}
                onChange={handleChange}
                placeholder="Facebook URL"
                name="facebookURL"
                label="Facebook URL"
                type="url"
                classname={"grid-item"}
              />
              <UIInput
                value={state.twitterURL}
                onChange={handleChange}
                placeholder="Twitter URL"
                name="twitterURL"
                label="Twitter URL"
                type="url"
              />
              <UIInput
                value={state.linkedinURL}
                onChange={handleChange}
                placeholder="LinkedIn URL"
                name="linkedinURL"
                label="LinkedIn URL"
                type="url"
              />
              <UIInput
                value={state.pinterestURL}
                onChange={handleChange}
                placeholder="Pinterest URL"
                name="pinterestURL"
                label="Pinterest URL"
                type="url"
              />
              <UIInput
                value={state.instagramURL}
                onChange={handleChange}
                placeholder="Instagram URL"
                name="instagramURL"
                label="Instagram URL"
                type="url"
              />
              <UIInput
                value={state.tiktokURL}
                onChange={handleChange}
                placeholder="TikTok URL"
                name="tiktokURL"
                label="TikTok URL"
                type="url"
              />
            </div>
            <div
            // className={`input ${isImageError.logo && "border border-red-500 px-3 rounded-xl"}`}
            >
              <label className="label">Sponsor Images</label>
              <input
                name="sponserImages"
                type="file"
                onChange={handleFileChange}
                accept="image/*"
                multiple
                className="block w-full text-slate-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:font-semibold
                  file:bg-[#0b005a]/80 file:text-white
                  hover:file:bg-[#0b005a]"
              />
            </div>
            <div className="btn-wrapper">
              <UIButton label="Save" className="form-btn" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
