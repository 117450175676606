import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataService } from "../../../utils/axios";
import { VOTING_USER_BASE_URI } from "../../../utils/config";

export const GetSponsersList= createAsyncThunk(
  '@voting-dashboard/sponsersList',
  async ( _ , thunkAPI) =>{
    try{
      const res  =  await dataService.getData(`${VOTING_USER_BASE_URI}/sponsers`);
      const resData = res.data
      return resData;
    }catch(error : any){
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)