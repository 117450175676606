import React, { useState, useEffect } from "react"
import CandidateCard from "./CandidateCard/CandidateCard"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../../components/ui/carousel"
import { VOTING_USER_BASE_URI } from "../../../utils/config"
import axios from "axios"

export default function ListOfCandidates() {
  const [candidates, setCandidates] = useState<any[]>([])

  const getCandidates = async () => {
    const aa: any = await axios.get(`${VOTING_USER_BASE_URI}/candidates`, {
          headers: {
              "x-api-key": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb25JZCI6ImQ4MmIzNjk0ZDlmOGNjNDkiLCJpc3MiOiJ2b3RpbmdfYXBwbGljYXRpb25fb3JnYW5pemF0aW9uX21hbmFnZW1lbnQiLCJpYXQiOjE3MjEyODE3MzAsImV4cCI6MTc4NDE4MjY1M30.RNv8kH8XYkx77DOsju6wVnJH3_i6scncnY4vNq_k-Bo`,
          }
      })
      setCandidates(aa?.data?.data?.rows)
    }

    useEffect(() => {
      getCandidates()
    }, [])

  return (
    <>
      <h1 className="dashboard-section-title">List Of Candidates</h1>
      <div className="pr-10 pl-10">
        <Carousel
          opts={{
            align: "start",
          }}
        >
          <CarouselContent>
            {candidates.map((candidate, index) => (
              <CarouselItem
                key={index}
                className="md:basis-1/3 lg:basis-1/4 sm:basis-1/2"
              >
                <CandidateCard
                  name={candidate.name}
                  campaign={candidate?.votingCampaign?.title}
                  stage={candidate?.votingStageCandidates[0]?.votingCampaignStage?.title}
                  // coupon={candidate?.coupon}
                  // couponVote={candidate?.coupon_vote}
                  // smsVote={candidate?.sms_vote}
                  imageUrl={candidate?.profilePicture}
                  nationality={candidate?.nationality}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </>
  )
}
