import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ActionsInitialState } from "../interface/actions.interface";
import { RootStore } from "../../store";
import { EditCandidatesProfileThunk } from "../../actions/candidates/editCandidatesProfile.actions";

const initialState : ActionsInitialState = {
  isFulfilled : false,
  isLoading : false,
  isRejected : false
}

export const EditCandidatesProfileSlice = createSlice({
  name : '@voting-dashboard/edit-candidates-profile-slice',
  initialState,
  reducers : {
    resetState : (state : ActionsInitialState) => {
      state.isFulfilled = false
      state.isRejected = false
      state.isLoading = false    
    }
  },
  extraReducers : (builder) => {
    builder
    .addCase(EditCandidatesProfileThunk.pending , (state : ActionsInitialState) => {
      state.isFulfilled = false
      state.isRejected = false
      state.isLoading = true
    })
    .addCase(EditCandidatesProfileThunk.fulfilled , (state : ActionsInitialState , action : PayloadAction<boolean | undefined>) => {
      state.isLoading = false    
      state.isRejected = action.payload ? false : true
      state.isFulfilled = action.payload ?? false
    })
    builder.addCase(EditCandidatesProfileThunk.rejected , (state : ActionsInitialState) => {
      state.isFulfilled = false
      state.isLoading = false
      state.isRejected = true
    })
  }
})

export const { resetState } = EditCandidatesProfileSlice.actions
export const EditCandidatesProfileData = ( state : RootStore ) => state.edit_candidate_profile
export default EditCandidatesProfileSlice.reducer