import React from 'react'
import { FaSearch } from 'react-icons/fa'

interface Props {
    label: string
    placeholder: string
    searchQuery: string
    setSearchQuery: (value: string) => void
}
const Input: React.FC<Props> = ({ searchQuery, setSearchQuery, label, placeholder }) => {
    return (
        <div className="w-full h-20  flex items-center justify-end relative mb-8">
            <span className="text-xl font-normal absolute right-8 top-[50%] -translate-y-1/2"><FaSearch /></span>
            <input type="text" placeholder={placeholder}
                className="w-3/12 h-16 pl-5 placeholder:text-lg outline-none rounded-lg border-[1px] border-[var(--c-primary)] "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
        </div>
    )
}
export default Input