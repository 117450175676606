import React, { useEffect, useRef } from "react"
import ContestantCard from "../../ui/contestantcard/ContestantCard"
import { useParams } from "react-router-dom"
import PrevBtn from "../../ui/prevbutton/BackBtn"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { loginData } from "../../redux/slices/login/login.slices"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import { getVotingCampaignUpdated } from "../../redux/actions/votingCampaign/getAllVotingCampaign"
import { dataService } from "../../utils/axios"

const Performance = ({ title }: { title: any }) => {
  let { id, csid } = useParams()
  const dispatch = useAppDispatch()
  const loginInfo = TypedSelector(loginData)
  const xapiKey = TypedSelector(xApiKey)
  const { data } = useSelector((state: RootStore) => state.votingCampaign)
  const votingCampaignData = data?.data?.rows

  useEffect(() => {
    if (loginInfo?.data?.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
      dispatch(getVotingCampaignUpdated())
    }
  }, [loginInfo, xapiKey, dispatch])

  return (
    <div className="performance-wrapper">
      <div className="performance-top">
        <PrevBtn />
        <h1>Performance - {title}</h1>
      </div>
      <div className="candidate-container">
        <div className="card-wrapper">
          {
            votingCampaignData && votingCampaignData?.map((data) => {
              return (
                <ContestantCard
                  profile={data.logo}
                  title={data.title}
                  id={data.id}
                  key={data.id}
                />
              )
            })}
          {
            (votingCampaignData?.length === 0) && <p> No Stages to display</p>
          }
        </div>
      </div>
    </div>
  )
}

export default Performance
