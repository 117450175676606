import React from "react"
import { Bar } from "react-chartjs-2"
import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"

Chart.register(CategoryScale)
Chart.defaults.color = "black"

const BarC = ({ data }: { data: any }) => {
  const activeCount = data.activeCampaigns?.data?.count || 0
  // const allCount = data.allCampaigns?.data?.count || 0
  // const upcomingCount = data.upcomingCampaigns?.data?.count || 0
  const allCount = 5
  const upcomingCount = 3
  return (
    <div className="chartwrapper">
      <Bar
        data={{
          labels: ["Active Campaigns", "All Campaigns", "Upcoming Campaigns"],
          datasets: [
            {
              label: "Number of Campaigns",
              data: [activeCount, allCount, upcomingCount],
              backgroundColor: "#0b005a",
              borderColor: "#0e005c",
              borderWidth: 1,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  )
}

export default BarC
