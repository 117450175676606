import { Link, useNavigate, useParams } from "react-router-dom"
import Navbar from "../../../src/components/navbar/Navbar"
import PrevBtn from "../../../src/ui/prevbutton/BackBtn"
import TableTop from "../../../src/components/tabletop/Tabletop"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { dataService } from "../../utils/axios"
import { useAppDispatch } from "../../utils/redux.utils"
import React, { useEffect, useRef } from "react"
import { GetJudgesByVotingCampaign } from "../../../src/redux/actions/Judges/JudgesList.action"
import Loading from "../../../src/components/Loading/Loading"
import { S3_URL } from "../../constants/constants"
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../src/utils/toastify"
import { DeleteJudge } from "../../../src/redux/actions/Judges/JudgeDelete.action"
import { resetState } from "../../../src/redux/slices/Judges/DeleteJudge.slices"

const Judges = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const didMount = useRef<boolean>(false)
  const dispatch = useAppDispatch()

  const { data: JudgeData, isPending } = useSelector(
    (state: RootStore) => state.judgesby_campaign_id,
  )
  const {
    isPending: deletePending,
    isFulfilled: deleteFulfilled,
    isRejected: deleteRejected,
  } = useSelector((state: RootStore) => state.delete_Judge)
  const { data: AuthData } = useSelector((state: RootStore) => state.login)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      dataService.setApiKey(x_api!)
      dataService.setToken(AuthData.token!)
      if (!id) navigate("/votingCampaign")

      id && dispatch(GetJudgesByVotingCampaign(id))
    }
  }, [])

  useEffect(() => {
    ;(deleteRejected || deletePending || deleteFulfilled) && dismissToast()
    if (deleteRejected) {
      errorToast("Error Deleteing Judge ! Net Problem ??")
      dispatch(resetState())
    }
    if (deletePending) {
      loadingToast("deleting Judge")
      dispatch(resetState())
    }
    if (deleteFulfilled) {
      successToast("Judge Deleted Successfully")
      dispatch(GetJudgesByVotingCampaign(id!))
      dispatch(resetState())
    }
  }, [deleteFulfilled, deletePending, deleteRejected])

  const deleteJudge = (slug: string) => {
    dispatch(DeleteJudge(slug))
  }

  if (isPending) {
    return <Loading />
  }

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Judges" showBars />
        </div>
        <div className="table-wrapper-top">
          <TableTop />
        </div>
        <div className="w-full flex items-center justify-end pr-10 pb-5">
          <input
            type="text"
            placeholder="Search user"
            className="placeholder:text-medium pl-3 outline-none border-1 rounded-lg py-1 border-[#0b005a]"
            value={"search"}
          />
        </div>
        <div className="table-wrapper">
          <table className="table-wrapper-table" border={0}>
            <thead>
              <tr>
                <th>SN</th>
                <th>Name</th>
                <th>Post</th>
                <th>Image</th>
                <th>Descriptions</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody className="table-body">
              {JudgeData && JudgeData.length !== 0 ? (
                JudgeData.map((data: any, index: number) => (
                  <tr key={data.id}>
                    <td className="sn">{index + 1}</td>
                    <td>{data.name}</td>
                    <td>{data.post}</td>
                    <td className="image">
                      <img src={S3_URL.concat(data.image)} alt="pic" />
                    </td>
                    <td>{data.description}</td>
                    <td className="action">
                      <Link to={`edit/${data.id}`}>
                        <button className="bttn edit-btn">Edit</button>
                      </Link>

                      <button
                        onClick={() => deleteJudge(data.id)}
                        className="bttn delete-btn"
                        disabled={deletePending}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="notfound">
                    Data not found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Judges
