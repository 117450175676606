import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/store';
import CampaignStatusDropdown from '../../ui/campaign-status-dropdown/campaign-status-dropdown';
import ContestantCard from '../../ui/contestantcard/ContestantCard';

export const CampaignStatus = () => {
  const { data } = useSelector((state: RootStore) => state.votingCampaign);
  const votingCampaigns = data?.data.rows
  const [selectedStatus, setSelectedStatus] = useState<number>(1);

  const getCurrentDateTime = () => new Date();
  const currentDateTime = getCurrentDateTime();

  const getCampaignStatus = (startDateTime: string, endDateTime: string) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);

    if (currentDateTime < start) {
      return 'Upcoming';
    } else if (currentDateTime >= start && currentDateTime <= end) {
      return 'Active';
    } else {
      return 'Recently Finished';
    }
  };
  const filteredCampaigns = votingCampaigns?.filter(campaign => {
    const campaignStatus = getCampaignStatus(campaign.startDateTime, campaign.endDateTime);

    // Filter campaigns based on selected status
    if (selectedStatus === 1) {
      return campaignStatus === 'Upcoming';
    } else if (selectedStatus === 2) {
      return campaignStatus === 'Active';
    } else if (selectedStatus === 3) {
      return campaignStatus === 'Recently Finished';
    }
    return true;
  });

  return (
    <>
      <CampaignStatusDropdown current={selectedStatus} setCurrent={setSelectedStatus} />
      <ul className='flex gap-8 md:gap-16'>
        {filteredCampaigns?.map(campaign => (
          <li key={campaign.id}>
            <ContestantCard
              title={campaign.title}
              profile={campaign.logo}
              id={campaign.id}
            />
          </li>
        ))}
        {
          filteredCampaigns?.length == 0 && <h2>No {selectedStatus === 1 ? 'Upcomming' : selectedStatus == 2 ? 'Active' : 'Recently Completed'}  campaign to show</h2>
        }
      </ul>
    </>
  );
};

export default CampaignStatus;
