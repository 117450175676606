import { createAsyncThunk } from "@reduxjs/toolkit"
import { CampaignCreationResponseData, NewState } from "../../../slices/interface/campaignStage.interface";
import { api } from "../../../../utils/axios";
// export interface PropsData {
//     data: NewState
//     xApiKey: string
//     authToken: string
// }
export const createVCampaignStage = createAsyncThunk(
    "voting-campaign/create",
    async ({ data }: { data: NewState }, thunkAPI) => {
        try {
            const response = await api.post<CampaignCreationResponseData>("/voting-campaign-stages", data)
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message)
        }
    },
)
