import React from 'react'

const Loading : React.FC = () => {
  return (
    <div className="spinner-box flex items-center justify-center w-full h-screen">
      <div className="spinner-box-quarter"></div>
    </div>
  )
}

export default Loading