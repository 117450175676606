import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../redux/store";
import { isExpired } from "../../utils/helper";
import { getXApikey } from "../../redux/actions/XApiKey/xapikey.actions";
import { toggleMenu } from "../../redux/slices/systemSlice";
import { logout } from "../../redux/slices/login/login.slices";
import { DUMMY_PROFILE } from "../../constants/images";
import { useAppDispatch } from "../../utils/redux.utils";

interface NavbarProps {
  name: string;
  showBars: boolean;
  back?: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ name, showBars, back }) => {
  const didMount = useRef<boolean>(false);
  const { x_api, expiry } = useSelector((state: RootStore) => state.x_api_key);

  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleBar = () => {
    dispatch(toggleMenu());
  };

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      if (!x_api || isExpired(expiry)) appDispatch(getXApikey());
    }
  }, [dispatch, x_api, expiry, appDispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar">
      <div className="navbar-left">
        {back ? (
          <i
            className="fa-regular fa-chevron-left navbar-left--icon__back"
            onClick={back}
          ></i>
        ) : null}
        {showBars ? (
          <i
            className={`navbar-left--icon__bar fa-light fa-bars`}
            onClick={handleToggleBar}
          ></i>
        ) : null}
        <h2 className="navbar-left--text">{name}</h2>
      </div>
      <div className="navbar-right">
        <button
          onClick={handleLogout}
          className="flex items-center justify-between gap-2 bg-red-400 text-white font-semibold p-2 rounded-xl"
        >
          Log Out <i className="fa-regular fa-arrow-right-from-bracket"></i>
        </button>
        <div
          className="navbar-right--profile relative"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <img src={DUMMY_PROFILE} alt="User profile" className="cursor-pointer" />
          {dropdownOpen && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-30">
              <a href="#" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Profile</a>
              <a href="organization-settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Organization Setting</a>
              <a href="#" onClick={handleLogout} className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Logout</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
