import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import TableTop from "../tabletop/Tabletop"
import { StageRow } from "../../redux/slices/interface/stageByCampaignId.interface"
import DateCountdown from "../../ui/daysleft/DaysCountDown"
import { S3_URL } from "../../constants/constants"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { loginData } from "../../redux/slices/login/login.slices"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import { deleteCStageById } from "../../redux/actions/votingCampaign/stages/deleteCStageById.action"
import { deleteVCStage } from "../../redux/slices/votingCampaign/stage/deletVCStageByID.slice"
import { errorToast, successToast } from "../../utils/toastify"
import { useDebounce } from "../../hooks/useDebounce"
import Input from "../search/Input"
import { stageByCampaignIdData } from "../../redux/slices/votingCampaign/stageByCampaignId.slice"
import { getStageByCampaignId } from "../../redux/actions/votingCampaign/stageByCampaignId.action"
import { dataService } from "../../utils/axios"
export const CampaignStageTable = () => {
  const loginInfo = TypedSelector(loginData)
  const deleted = TypedSelector(deleteVCStage)
  const xapi = TypedSelector(xApiKey)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const stageData = TypedSelector(stageByCampaignIdData)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [votingStageData, setVotingStageData] = useState(
    stageData.data?.data.rows,
  )
  const { isloading, data, isError } = useDebounce(
    searchQuery,
    votingStageData,
    200,
  )
  const [filteredData, setFilteredData] = useState<StageRow[] | undefined>(
    votingStageData,
  )
  const [cindex, setCindex] = useState<number | null>(null)
  const handleCampaignStage = (stageId: string) =>
    dispatch(deleteCStageById({ id: stageId }))
  useEffect(() => {
    if (loginInfo.data.token && xapi.x_api) {
      dataService.setApiKey(xapi.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo, xapi])
  useEffect(() => {
    const pass = {
      campaignId: id as string,
    }
    if (id || deleted.data?.success) {
      dispatch(getStageByCampaignId(pass))
    }
  }, [id, dispatch, deleted])
  useEffect(() => {
    if (deleted.data?.success) {
      successToast("Deleted")
    }
    if (deleted.isError) {
      errorToast("Something went wrong")
    }
    // reset here
  }, [deleted])
  useEffect(() => {
    setFilteredData(data)
  }, [data])
  useEffect(() => {
    setVotingStageData(stageData.data?.data.rows)
    setFilteredData(stageData.data?.data.rows)
  }, [stageData])
  return (
    <>
      <TableTop />
      <Input
        label="Search"
        placeholder="search voting stage"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <div className="table-wrapper">
        <table className="table-wrapper-table rounded-lg overflow-hidden border">
          <thead>
            <tr>
              <th rowSpan={2}>SN</th>
              <th rowSpan={2}>Logo</th>
              <th rowSpan={2}>title</th>
              <th colSpan={2}>Limit</th>
              <th rowSpan={2}>TimeLine</th>
              <th rowSpan={2}>Candiates</th>
              <th rowSpan={2}>Action</th>
            </tr>
            <tr>
              <th>Email</th>
              <th className="border border-[#525050]">Sms</th>
            </tr>
          </thead>
          {!stageData.isLoading ? (
            <tbody className="table-body">
              {filteredData ? (
                filteredData.map((currentRow, index) => {
                  return (
                    <tr key={currentRow.id} className="cursor-pointer">
                      <td className="sn">{index + 1}</td>
                      <td className="image ">
                        <img
                          src={S3_URL.concat(currentRow.logo)}
                          alt={currentRow.title}
                        />
                      </td>
                      <td>{currentRow.title}</td>
                      <td>{currentRow.emailVotingLimit}</td>
                      <td>{currentRow.smsVotingLimit}</td>
                      <td>
                        <DateCountdown
                          startDate={currentRow.startDateTime}
                          endDate={currentRow.endDateTime}
                        />
                      </td>
                      <td className="managecandidate">
                        <Link
                          to={`/votingcampaign/votingcampaignstage/stagecandidate/${currentRow.id}`}
                        >
                          more...
                        </Link>
                      </td>
                      <td className="action">
                        <Link
                          to={`edit/${currentRow.id}`}
                          state={{ current: currentRow }}
                        >
                          <button className="bttn edit-btn">Edit</button>
                        </Link>
                        <button
                          className="bttn delete-btn"
                          onClick={() => [
                            handleCampaignStage(currentRow.id),
                            setCindex(index),
                          ]}
                        >
                          {deleted.isLoading && index === cindex
                            ? "Deleting"
                            : "Delete"}
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <h1>Data not found</h1>
              )}
            </tbody>
          ) : (
            <h1>Loading</h1>
          )}
        </table>
      </div>
    </>
  )
}
