// src/redux/slices/organization/updateOrganizationSetting.slice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrganizationSettingsInterface } from '../interface/organizationSetting.interface';
import { UpdateOrganizationSettingThunk } from '../../actions/organization/updateOrganization.action';
import { RootStore } from '../../store';

interface ActionInitialState {
  isLoading: boolean;
  isFulfilled: boolean;
  isRejected: boolean;
  data: OrganizationSettingsInterface | null;
}

const initialState: ActionInitialState = {
  isLoading: false,
  isFulfilled: false,
  isRejected: false,
  data: null,
};

const updateOrganizationSettingSlice = createSlice({
  name: '@organization-settings/updateOrganizationSettingSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      state.isFulfilled = false;
      state.isRejected = false;
      state.isLoading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateOrganizationSettingThunk.pending, (state) => {
        state.isLoading = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })
      .addCase(UpdateOrganizationSettingThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.isFulfilled = true;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(UpdateOrganizationSettingThunk.rejected, (state) => {
        state.isLoading = false;
        state.isFulfilled = false;
        state.isRejected = true;
      });
  }
  
});

export const { resetState } = updateOrganizationSettingSlice.actions;
export const UpdateOrganizationSettings = (state: RootStore) => state.update_organization_setting;
export default updateOrganizationSettingSlice.reducer;
