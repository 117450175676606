import { useEffect, useState } from "react"
import Navbar from "../../components/navbar/Navbar"
import { Link } from "react-router-dom"
import { S3_URL, votingcampaignTableHeading } from "../../constants/constants"
import TableTop from "../../components/tabletop/Tabletop"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import {
  Rows,
  votingCampaignDatas,
} from "../../redux/slices/votingCampaign/votingCamp.slice"
import DateCountdown from "../../ui/daysleft/DaysCountDown"
import { deleteCampaignById } from "../../redux/actions/votingCampaign/deleteCampaignbyId.action"
import {
  InitialLoginInterface,
  loginData,
} from "../../redux/slices/login/login.slices"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import {
  deleteCampaign,
  resetCampaign,
} from "../../redux/slices/votingCampaign/deleteCampaign.slice"
import { errorToast, successToast } from "../../utils/toastify"
import { getVotingCampaignUpdated } from "../../redux/actions/votingCampaign/getAllVotingCampaign"
import { useDebounce } from "../../hooks/useDebounce"
import Input from "../../components/search/Input"
import { dataService } from "../../utils/axios"
import { FaUserGroup } from "react-icons/fa6"
import { convertIsoToNormalDateTime } from "../../utils/helper"
export const VotingCampaign = () => {
  const dispatch = useAppDispatch()
  const votingData = TypedSelector(votingCampaignDatas)
  const loginInfo = TypedSelector(loginData)
  const xapiKey = TypedSelector(xApiKey)
  const deleteV = TypedSelector(deleteCampaign)
  const [searchQuery, setSearchQuery] = useState<string>("")
  const { isloading, data, isError } = useDebounce(
    searchQuery,
    votingData?.data?.data?.rows,
    100,
  )
  const [filteredData, setFilteredData] = useState<Rows[]>([])
  const [cindex, setIndex] = useState<number | null>(null)
  useEffect(() => {
    if (loginInfo.data.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
    }
  }, [loginInfo.data, xapiKey])
  const handleCampaignDelete = (id: string) => {
    dispatch(deleteCampaignById(id))
    dispatch(resetCampaign())
    if(deleteV.data?.success){
      successToast("Deleted Successfully")
      dispatch(getVotingCampaignUpdated())
      dispatch(resetCampaign())
    }
  }
  useEffect(() => {
    dispatch(getVotingCampaignUpdated())
  }, [loginInfo, dispatch])

  // useEffect(() => {
  //   // if (deleteV.data?.success) {
  //   //   successToast("Deleted Successfully")
  //   //   dispatch(getVotingCampaignUpdated())
  //   //   dispatch(resetCampaign())
  //   // }
  //   // if (deleteV.isError) {
  //   //   errorToast("Something went wrong")
  //   //   dispatch(resetCampaign())
  //   // }
  // }, [])
  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(data)
    }
  }, [data])

  useEffect(() => {
    if (votingData?.data?.data?.rows) {
      setFilteredData(votingData.data.data.rows)
    }
  }, [votingData])

  return (
    <div className="framecontainer select-none">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Voting Campaign" showBars />
        </div>
        <TableTop />
        <Input
          label="Search: "
          placeholder="search voting campaign"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <div className="table-wrapper">
          {votingData?.data?.data?.rows?.length !== 0 ? (
            <table
              className="table-wrapper-table rounded-lg overflow-hidden border"
              border={0}
            >
              <thead>
                <tr className=" tracking-wide">
                  {votingcampaignTableHeading.map((heading, index) => (
                    <th key={JSON.stringify(index)}>{heading.name}</th>
                  ))}
                </tr>
              </thead>
              {!votingData.isLoading ? (
                <tbody className="table-body">
                  {filteredData.map((campaign: any, index: number) => (
                    <tr
                      key={JSON.stringify(index)}
                      className="cursor-pointer hover:bg-[#0b005a19] duration-150"
                    >
                      <td className="sn">{index + 1}</td>
                      <td className="image relative">
                        <img src={S3_URL.concat(campaign.logo)} alt="pic" />
                      </td>
                      <td>{campaign.title}</td>

                      <td className="managecandidate">
                        <Link
                          to={`/votingcampaign/campaigncandidate/${campaign.id}&vc=${campaign.title}`}
                        >
                          {/* <FaUserGroup size={24}/> */}
                          more...
                        </Link>
                      </td>
                      <td className="managecandidate">
                        <Link
                          to={`/votingcampaign/votingcampaignstage/${campaign.id}`}
                        >
                          more...
                        </Link>
                      </td>
                      <td className="managecandidate">
                        <Link to={`/votingcampaign/coupan/${campaign.id}`}>
                          more...
                        </Link>
                      </td>
                      {/* //judges */}
                      <td className="managecandidate">
                        <Link to={`/votingcampaign/Judges/${campaign.id}`}>
                          more...
                        </Link>
                      </td>
                      <td>
                        <DateCountdown
                          startDate={campaign.startDateTime}
                          endDate={campaign.endDateTime}
                        />
                      </td>

                      <td>{convertIsoToNormalDateTime(campaign.inserted)}</td>
                      <td className="action">
                        <Link to={`edit/${campaign.id}`} state={{ campaign }}>
                          <button className="bttn edit-btn">Edit</button>
                        </Link>
                        <button
                          className="bttn delete-btn"
                          onClick={() => [
                            handleCampaignDelete(campaign.id),
                            setIndex(index),
                          ]}
                        >
                          {deleteV.isLoading && index === cindex
                            ? "Deleting"
                            : "Delete"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="table-body">
                  <tr className="cursor-pointer hover:bg-[#0b005a19] duration-150">
                    {votingcampaignTableHeading.map((heading, index) => (
                      <td key={JSON.stringify(index)}>Loading...</td>
                    ))}
                  </tr>
                </tbody>
              )}
            </table>
          ) : (
            <h1>Nothing to show</h1>
          )}
        </div>
      </div>
    </div>
  )
}
