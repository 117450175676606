import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { DeleteJudge } from "../../actions/Judges/JudgeDelete.action";

const initialState: any = {
  isPending: false,
  isFulfilled: false,
  isRejected: false
};

export const DeleteJudgeSlice = createSlice({
  name: '@voting-dashboard/delete-judge',
  initialState,
  reducers: {
    resetState:(state)=>{
        state.isFulfilled = false
        state.isRejected = false
        state.isLoading = false
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(DeleteJudge.pending, (state) => {
        state.isPending = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })
      .addCase(DeleteJudge.fulfilled, (state, action: PayloadAction<any>) => {
        state.isFulfilled = action.payload || true; 
        state.isPending = false;
        state.isRejected = false;
      })
      .addCase(DeleteJudge.rejected, (state) => {
        state.isFulfilled = false;
        state.isPending = false;
        state.isRejected = true;
      });
  }
});
export const  { resetState } = DeleteJudgeSlice.actions
export const DeleteJudgeData = (state: RootStore) => state.delete_Judge;
export default DeleteJudgeSlice.reducer;
