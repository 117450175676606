import systemReducer from "./slices/systemSlice"
import votingCampainSlice from "./slices/votingCampaign/votingCamp.slice"
import stageByCampaignIdSlice from "./slices/votingCampaign/stageByCampaignId.slice"
import loginSlice from "./slices/login/login.slices"
import dashboardSlice from "./slices/dashboard/dashboard.slice"
import xApiKeySlice from "./slices/XapiKey/xApiKey.slice"
import CouponByCampaignReducer from "./slices/coupon/getCouponByCampaignId.slices"
import CreateCouponSliceReducer from "./slices/coupon/createCoupon.slices";
import UpdateCouponReducer from "./slices/coupon/editCoupon.slices"
import DeleteCouponReducer from "./slices/coupon/deleteCoupon.slices"
import GetAllCandidatesByVotingCampaignReducers from "./slices/candidates/getAllCandidatesByCampaign.slice"
import faqSlice from "./slices/faq/faq.slice"
import createVCStageSlice from "./slices/votingCampaign/stage/createVCStage.slice"
import CreateCandidatesReducer from "./slices/candidates/createCandidates.slices"
import editCandidatesReducer from "./slices/candidates/editCandidates.slice"
import deleteCandidatesReducer from "./slices/candidates/deleteCandidates.slices"
import couponByIdReducer from "./slices/coupon/couponById.slices"
import GetAllCandidateByVotingStageIdReducer from "./slices/candidates/getAllCandidatesbyVotingStageId.slices"
import createVotingCampaign from './slices/votingCampaign/CreateVCamp.slice';
import updateVCampaign from './slices/votingCampaign/updateVCampaign.slice';
import deleteCampaignReducer from "./slices/votingCampaign/deleteCampaign.slice"
import updateVCStageSlice from "./slices/votingCampaign/stage/updateVCStage.slice"
import deleteVCStageSlice from './slices/votingCampaign/stage/deletVCStageByID.slice';
import votingAggregatorReducer from "./slices/votingAggregator/votingAggregator.slices"
import getUsersReducer from "./slices/user/getUsers.slices"
import BlockUserReducer from "./slices/user/blockUser.slices"
import DeleteUserReducer from "./slices/user/deleteUser.slices"
import NewsDataReducer from "./slices/news/allnews.slices"
import IndividualNewsDataReducer from "./slices/news/newsById.slices"
import CreateNewsReducer from "./slices/news/createNews.slices"
import EditNewsReducer from "./slices/news/editNews.slices"
import DeleteNewsReducers from "./slices/news/deleteNews.slices"
import updateCampaignLogoSlice from './slices/votingCampaign/updateLogo.slice';
import editNewsBannerReducer from "./slices/news/editNewsBanner.slices"
import getCandidatesByStageReducer from './slices/votingCampaign/stage/getCandidates.slice'
import EditCandidatesProfileReducer from "./slices/candidates/editCandidatesProfile.slices"
import VoteDataReducer from "./slices/vote/allvote.slices"
import { combineReducers } from "@reduxjs/toolkit"
import  GetAllOrganizationSettingsSlice  from "./slices/organization/getAllOrganizationSetting.slice"
import  UpdateOrganizationSettings  from "./slices/organization/updateOrganization.slice"
import chartData from "./slices/charts/chart.slice"
import  selectAllCampaigns from "./slices/votingCampaign/stage/getCampaignStage.slice"
import CreateJudgeSliceReducer from "./slices/Judges/CreateJudges.slices"
import getJudgesByVotingCampaignIdReducer from "./slices/Judges/ListJudge.slices"
import CreateSponserSliceReducer from "./slices/Sponers/CreateSponsers.slices"
import getSponsersReducer from "./slices/Sponers/ListSponsers.slices"
import delSponsersReducer from "./slices/Sponers/DeleteSponsers.slices"
import DeleteJudgeSliceReducer from "./slices/Judges/DeleteJudge.slices"
import GetJudgeSliceByIdReducer from "./slices/Judges/GetOneJudge.slices"
import UpdateJudgeSliceReducer from "./slices/Judges/UpdateJudge.slices"
import GetSponserSliceByIdReducer from "./slices/Sponers/GetOneSponser.slices"
import UpdateSponserSliceReducer from "./slices/Sponers/UpdateSponsers.slices"


//register reducers
export const rootReducer = combineReducers({
  //! system
  systemState: systemReducer,

  //! votingcampaign
  votingCampaign: votingCampainSlice,
  stageByCampaginId: stageByCampaignIdSlice,
  create_voting_campaign: createVotingCampaign,
  update_campaign: updateVCampaign,
  delete_voting_campaign: deleteCampaignReducer,

  //! voting campaign stage
  update_campaign_stage: updateVCStageSlice,
  delete_voting_campaign_stage: deleteVCStageSlice,
  update_campaign_logo: updateCampaignLogoSlice,
  create_voting_camp_stage: createVCStageSlice,

  //! Auth
  login: loginSlice,

  //!x-api
  x_api_key: xApiKeySlice,

  //!dashboaed lp
  dashboard: dashboardSlice,

  get_all_organization_settings:GetAllOrganizationSettingsSlice,
  update_organization_setting:UpdateOrganizationSettings,
  // ! coupon
  coupon_by_campaign_id: CouponByCampaignReducer,
  coupon_by_id: couponByIdReducer,
  create_coupon: CreateCouponSliceReducer,
  update_coupon: UpdateCouponReducer,
  delete_coupon: DeleteCouponReducer,

  // !faq
  faq: faqSlice,

  chart:chartData,
  allCampaigns:selectAllCampaigns,

  // ! Judges
  create_judge_by_campiagnID: CreateJudgeSliceReducer,
  judgesby_campaign_id:getJudgesByVotingCampaignIdReducer,
  delete_Judge:DeleteJudgeSliceReducer,
  judge_by_id:GetJudgeSliceByIdReducer,
  update_judge_by_campiagnID:UpdateJudgeSliceReducer,
  //!sponsers 
  create_sponsers:CreateSponserSliceReducer,
  sponsers:getSponsersReducer,
  delete_Sponser:delSponsersReducer,
  sponser_by_id:GetSponserSliceByIdReducer,
  update_sponser:UpdateSponserSliceReducer,

  // candidate
  get_all_candidates_by_campaign_id: GetAllCandidatesByVotingCampaignReducers,
  get_all_candidates_by_stage_id: GetAllCandidateByVotingStageIdReducer,
  create_candidate: CreateCandidatesReducer,
  update_candidate: editCandidatesReducer,
  delete_candidate: deleteCandidatesReducer,
  get_candidates_by_stage_id: getCandidatesByStageReducer,
  edit_candidate_profile: EditCandidatesProfileReducer,

  // !vaoting aggregator
  voting_aggregator: votingAggregatorReducer,

  // !vote
  get_all_vote: VoteDataReducer,

  // user
  get_all_user: getUsersReducer,
  block_user: BlockUserReducer,
  delete_user: DeleteUserReducer,

  // !news
  news: NewsDataReducer,
  individual_news: IndividualNewsDataReducer,
  create_news: CreateNewsReducer,
  edit_news: EditNewsReducer,
  edit_news_banner: editNewsBannerReducer,
  delete_news: DeleteNewsReducers,
})