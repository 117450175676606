import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataService } from "../../../utils/axios";
import { VOTING_USER_BASE_URI } from "../../../utils/config";

export const GetJudgesByVotingCampaign = createAsyncThunk(
  '@voting-dashboard/getJudgesByVotingCampaignId',
  async (slug : string , thunkAPI) =>{
    try{
      const res  =  await dataService.getData(`${VOTING_USER_BASE_URI}/judge?votingCampaignId=${slug}`);
      const resData = res.data
      return resData;

    }catch(error : any){
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)