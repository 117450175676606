import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllVotingCampaigns, getActiveVotingCampaigns, getUpcomingVotingCampaigns } from "../../../actions/votingCampaign/stages/getCampaignStage.action";
import { RootStore } from "@/src/redux/store";

const initialCampaignState = {
  isFulfilled: false,
  isLoading: false,
  isRejected: false,
  data: null,
};

const initialState: any = {
  allCampaigns: { ...initialCampaignState },
  activeCampaigns: { ...initialCampaignState },
  upcomingCampaigns: { ...initialCampaignState },
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    resetState: (state) => {
      state.allCampaigns = { ...initialCampaignState };
      state.activeCampaigns = { ...initialCampaignState };
      state.upcomingCampaigns = { ...initialCampaignState };
    },
  },
  extraReducers: (builder) => {
    builder
      // All Campaigns
      .addCase(getAllVotingCampaigns.pending, (state) => {
        state.allCampaigns.isLoading = true;
        state.allCampaigns.isFulfilled = false;
        state.allCampaigns.isRejected = false;
      })
      .addCase(getAllVotingCampaigns.fulfilled, (state, action: PayloadAction<any | undefined>) => {
        state.allCampaigns.isFulfilled = true;
        state.allCampaigns.isLoading = false;
        state.allCampaigns.isRejected = action.payload ? false : true;
        state.allCampaigns.data = action.payload?.data || null;
      })
      .addCase(getAllVotingCampaigns.rejected, (state) => {
        state.allCampaigns.isLoading = false;
        state.allCampaigns.isFulfilled = false;
        state.allCampaigns.isRejected = true;
      })

      // Active Campaigns
      .addCase(getActiveVotingCampaigns.pending, (state) => {
        state.activeCampaigns.isLoading = true;
        state.activeCampaigns.isFulfilled = false;
        state.activeCampaigns.isRejected = false;
      })
      .addCase(getActiveVotingCampaigns.fulfilled, (state, action: PayloadAction<any | undefined>) => {
        state.activeCampaigns.isFulfilled = true;
        state.activeCampaigns.isLoading = false;
        state.activeCampaigns.isRejected = action.payload ? false : true;
        state.activeCampaigns.data = action.payload?.data || null;
      })
      .addCase(getActiveVotingCampaigns.rejected, (state) => {
        state.activeCampaigns.isLoading = false;
        state.activeCampaigns.isFulfilled = false;
        state.activeCampaigns.isRejected = true;
      })

      // Upcoming Campaigns
      .addCase(getUpcomingVotingCampaigns.pending, (state) => {
        state.upcomingCampaigns.isLoading = true;
        state.upcomingCampaigns.isFulfilled = false;
        state.upcomingCampaigns.isRejected = false;
      })
      .addCase(getUpcomingVotingCampaigns.fulfilled, (state, action: PayloadAction<any | undefined>) => {
        state.upcomingCampaigns.isFulfilled = true;
        state.upcomingCampaigns.isLoading = false;
        state.upcomingCampaigns.isRejected = action.payload ? false : true;
        state.upcomingCampaigns.data = action.payload?.data || null;
      })
      .addCase(getUpcomingVotingCampaigns.rejected, (state) => {
        state.upcomingCampaigns.isLoading = false;
        state.upcomingCampaigns.isFulfilled = false;
        state.upcomingCampaigns.isRejected = true;
      });
  },
});

export const { resetState } = campaignsSlice.actions;
export const selectAllCampaigns = (state: RootStore) => state.allCampaigns;

export default campaignsSlice.reducer;
