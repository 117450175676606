/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react"
import PrevBtn from "../../../../ui/prevbutton/BackBtn"
import Navbar from "../../../../components/navbar/Navbar"
import { UIInput } from "../../../../ui/uiinput/UIInput"
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../../utils/toastify"
import { useAppDispatch } from "../../../../utils/redux.utils"
import { EditCandidatesThunk } from "../../../../redux/actions/candidates/editCandidates.actions"
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../../../redux/store"
import { dataService } from "../../../../utils/axios"
import { resetState } from "../../../../redux/slices/candidates/editCandidates.slice"
import { CandidateInterface } from "../../../../redux/slices/interface/candidates.interface"
import { FaCamera } from "react-icons/fa"
import { CreateCandidateSchema } from "../../../../utils/zod/Candidate.schema"
import { useLocation, useNavigate } from "react-router-dom"
import { S3_URL } from "../../../../constants/constants"
import { EditCandidatesProfileThunk } from "../../../../redux/actions/candidates/editCandidatesProfile.actions"
import { resetState as CandidateResetState } from "../../../../redux/slices/candidates/editCandidatesProfile.slices"

export const CEditEntry = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const candidateData: CandidateInterface = location.state?.data
  const [toast, setToast] = useState<boolean>(false)
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    S3_URL.concat(candidateData?.profilePicture),
  )

  if (!location.state?.data) navigate(-1)

  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const { data } = useSelector((state: RootStore) => state.login)
  const { isFulfilled, isRejected, isLoading } = useSelector(
    (state: RootStore) => state.update_candidate,
  )
  const {
    isFulfilled: ProfilePictureUpdated,
    isRejected: ProfilePictureUploadFail,
  } = useSelector((state: RootStore) => state.edit_candidate_profile)

  const [state, setState] = useState<Partial<CandidateInterface>>({
    name: candidateData?.name,
    age: candidateData?.age,
    gender: candidateData?.gender,
    nationality: candidateData?.nationality,
    weight: candidateData?.weight,
    city: candidateData?.city,
    biography: candidateData?.biography,
    socialMediaFacebook: candidateData?.socialMediaFacebook,
    socialMediaInstagram: candidateData?.socialMediaInstagram,
    socialMediaTwitter: candidateData?.socialMediaTwitter,
    code: candidateData?.code,
    votingCampaignId: candidateData?.votingCampaignId,
  })

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target
    const newValue = name === "age" ? Number(value) : value
    setState((prevState) => ({ ...prevState, [name]: newValue }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const validationResult = CreateCandidateSchema.safeParse({ ...state })
    if (validationResult.error) {
      errorToast(JSON.parse(validationResult.error.message).at(0).message)
      return
    }
    dataService.setApiKey(x_api!)
    dataService.setToken(data.token!)
    const id = candidateData.id
    dispatch(EditCandidatesThunk({ id, ...state, age: state.age?.toString() }))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files && e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
    // Implement your file change logic here
    const form = new FormData()
    if (file) {
      form.append("profilePicture", file)
      dataService.setContentType("multipart/form-data")
      dataService.setApiKey(x_api!)
      dataService.setToken(data.token!)
      dispatch(
        EditCandidatesProfileThunk({ slug: candidateData.id, data: form }),
      )
    }
  }

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    setImagePreview(S3_URL + candidateData?.profilePicture)
  }, [candidateData?.profilePicture])

  useEffect(() => {
    if (isFulfilled) {
      toast && dismissToast()
      successToast("Successfully updated Candidates")
      setToast(true)
      dispatch(resetState())
      navigate(-1)
    }
    if (isRejected) {
      toast && dismissToast()
      errorToast("Error updating Candidates")
      setToast(true)
      dispatch(resetState())
    }
  }, [isFulfilled, isRejected, isLoading])
  useEffect(() => {
    if (ProfilePictureUpdated) {
      toast && dismissToast()
      successToast("Successfully updated news banner")
      dispatch(CandidateResetState())
      navigate(-1)
    }
    if (ProfilePictureUploadFail) {
      toast && dismissToast()
      errorToast("Error updating news banner")
      dispatch(resetState())
    }
  }, [ProfilePictureUpdated, ProfilePictureUploadFail])

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Edit Entry - Candidate" showBars />
        </div>

        <PrevBtn />
        <div className="form-wrapper">
          <form className="relative w-fit flex items-center gap-4">
            <div className="relative mb-6">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  width={100}
                  height={100}
                  className="rounded-full w-48 h-48 object-cover"
                  alt="Profile-preview"
                />
              ) : (
                <img
                  src={S3_URL + candidateData?.profilePicture}
                  width={100}
                  height={100}
                  className="rounded-full w-48 h-48 object-cover"
                  alt="Profile-set"
                />
              )}
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
              />
              <FaCamera
                onClick={handleImageClick}
                className="absolute w-8 h-8 bottom-4 right-2 fill-[var(--c-secondary)] cursor-pointer"
              />
            </div>
            <span className="opacity-[0.8]">Name: {candidateData?.name} </span>
          </form>

          {/* <div>
            <span className=" text-gray-700">Code: {candidateData?.code}</span>
          </div> */}
          <form className="form" onSubmit={handleSubmit}>
            <div className="input">
              <UIInput
                value={state.name}
                onChange={handleChange}
                name="name"
                label="Name"
                type="text"
              />
            </div>

            <div className="input">
              <UIInput
                value={state.votingCampaignId}
                onChange={handleChange}
                name="votingCampaignId"
                label="VotingCampaignId"
                type="text"
                readOnly
              />
            </div>
            <div className="input">
              <UIInput
                value={state.city}
                onChange={handleChange}
                name="city"
                label="City"
                type="text"
              />
            </div>

            <div className="input">
              <UIInput
                value={state.age}
                onChange={handleChange}
                name="age"
                label="Age"
                type="number"
              />
            </div>
            <div className="input">
              <label className="label">Gender</label>
              <select
                value={state.gender}
                onChange={handleChange}
                name="gender"
              >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </select>
            </div>
            <div className="input">
              <UIInput
                value={state.nationality}
                onChange={handleChange}
                name="nationality"
                label="Nationality"
                type="text"
              />
            </div>

            <div className="input">
              <UIInput
                value={state.weight}
                onChange={handleChange}
                name="weight"
                label="Weight in KG"
                type="text"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.socialMediaFacebook}
                onChange={handleChange}
                name="socialMediaFacebook"
                label="Facebook"
                type="text"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.socialMediaInstagram}
                onChange={handleChange}
                name="socialMediaInstagram"
                label="Instagram"
                type="text"
              />
            </div>
            <div className="input">
              <UIInput
                value={state.socialMediaTwitter}
                onChange={handleChange}
                name="socialMediaTwitter"
                label="Twitter"
                type="text"
              />
            </div>

            <div className="input textarea">
              <label className="label">Biography</label>
              <textarea
                value={state.biography}
                onChange={handleChange}
                name="biography"
                className="h-128"
              ></textarea>
            </div>

            <div className="btn-wrapper">
              <button className="btn form-btn" type="submit">
                {isLoading ? "Saving" : "Save"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
