import React, { useEffect } from "react"
import LineC from "./Line"
import BarC from "./Bar"
import PieC2 from "./Pie2"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { useSelector } from "react-redux"
import { RootStore } from "@/src/redux/store"
import { chartData } from "../../redux/slices/charts/chart.slice"
import { getChartData } from "../../redux/actions/chart/chart.action"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import {
  getAllVotingCampaigns,
  getActiveVotingCampaigns,
  getUpcomingVotingCampaigns,
} from "../../redux/actions/votingCampaign/stages/getCampaignStage.action"

const Charts = () => {
  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const chart = TypedSelector(chartData)

  const xapi = TypedSelector(xApiKey)

  useEffect(() => {
    if (xapi.x_api) {
      const data = {
        xapi: xapi.x_api,
      }
      dispatch(getAllVotingCampaigns(data))
      dispatch(getActiveVotingCampaigns(data))
      dispatch(getUpcomingVotingCampaigns(data))
    }
  }, [])

  useEffect(() => {
    if (x_api) {
      dispatch(getChartData())
    }
  }, [dispatch, x_api])

  const allCampaigns = useSelector((state: RootStore) => state.allCampaigns)
  console.log(allCampaigns)

  return (
    <>
      <h1 className="dashboard-section-title">List of Charts</h1>
      <div className="charts-container">
        <div className="chart chart-1">
          <h4 className="chart-title">Title</h4>
          <BarC data={allCampaigns} />
        </div>

        {/* <div className="chart chart-2">
          <h4 className="chart-title">Title</h4>
          <LineC />
        </div>
        <div className="chart chart-3">
          <h4 className="chart-title">Title</h4>
          <PieC2 />
        </div> */}
      </div>
    </>
  )
}

export default Charts
