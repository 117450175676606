import { createAsyncThunk } from "@reduxjs/toolkit"
import { api } from "../../../utils/axios"
export const deleteCampaignById = createAsyncThunk(
  "deleteCampaign",
  async (id: string, thunkAPI) => {
    try {
      const response = await api.delete(`/voting-campaign/${id}`,)
      return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.message)
    }
  },
)
