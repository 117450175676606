import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../../../utils/axios";
export interface PropsData {
    data: {
        candidateId: string
        votingCampaignStageId: string
    }
    xApiKey: string
    authToken: string
}
export const addStageCandidate = createAsyncThunk(
    "/voting-stage-candidates",
    async (data: PropsData, thunkAPI) => {
        try {
            const fetchOptions = {
                headers: {
                    "Authorization": `Bearer ${data.authToken}`,
                    "x-api-key": `Bearer ${data.xApiKey} `,
                }
            };
            const response = await api.post("/voting-stage-candidates", data.data, {
                headers: fetchOptions.headers
            })
            console.log(response.data)
            return response.data
        } catch (error: any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.message)
        }
    },
)
