import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  CouponInterface,
  initialCouponSliceData,
} from "../interface/coupon.interface"
import { GetCouponsByVotingCampaignId } from "../../actions/coupon/getCouponByCampaignId.actions"
import { RootStore } from "../../store"
import { GetJudgesByVotingCampaign } from "../../actions/Judges/JudgesList.action"
import { GetSponsersList } from "../../actions/sponsers/sponsersList.action"
import Sponsers from "../../../../src/pages/Sponsers/Sponsers"

interface Sponsers {
  id: number
  name: string
  logo: string
  contact: string
  shortContent: string
  largeTitle: string
}

interface InitialState {
  data: Sponsers[] | null
  isFulfilled: boolean
  isPending: boolean
  isRejected: boolean
}

const initialState: InitialState = {
  data: null, // Default to null
  isFulfilled: false,
  isPending: false,
  isRejected: false,
}

export const getSponsers = createSlice({
  name: "@voting-dashboard/sponsersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetSponsersList.pending, (state) => {
        state.isPending = true
        state.isRejected = false
        state.isFulfilled = false
      })
      .addCase(
        GetSponsersList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isPending = false
          state.isRejected = false
          state.isFulfilled = true

          // returning fulfilled response
          state.data = action.payload
        },
      )
      .addCase(GetSponsersList.rejected, (state) => {
        state.isPending = false
        state.isFulfilled = false
        state.isRejected = true
      })
  },
})

export const getSponser = (state: RootStore) => state.sponsers
export default getSponsers.reducer
