import { createAsyncThunk } from "@reduxjs/toolkit"
import { ResponseInterface } from "../../slices/votingCampaign/votingCamp.slice"
import { api } from "../../../utils/axios"
export const getVotingCampaignUpdated = createAsyncThunk(
    "/voting-campaign/all",
    async () => {
        const response = await api.get<ResponseInterface>("/voting-campaign")
        return response.data
    },
)
