import { z } from "zod"
import { ISODateTimeString, stringField } from "../common.schema"
export const CreateCampaignStage = z.object({
    title: stringField("title"),
    startDateTime: ISODateTimeString("campaign stage start date"),
    endDateTime: ISODateTimeString("campaign stage end date"),
    smsVotingLimit: stringField("sms limit"),
    emailVotingLimit: stringField("email limit"),
    description: stringField("description"),
    logo: z.instanceof(File, { message: "choose Logo" }),
    banner: z.instanceof(File, { message: "choose Banner" }),
    votingCampaignId: stringField("campaignID")
})
