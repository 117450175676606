import React from "react";
import { S3_URL } from "../../../../constants/constants";

const CandidateCard: React.FC<{ name: string; couponVote?: number; smsVote?: number; coupon?: string; campaign?: string; stage?: string, imageUrl: string, nationality?: string }> = ({ name, coupon, campaign, stage, couponVote, smsVote, imageUrl, nationality }) => {
  return (
    <div className="mr-5 cursor-pointer rounded-xl bg-white p-2 shadow duration-150 hover:scale-105 hover:shadow-md">
      <div className="h-56 overflow-hidden"> {/* Adjusted height to make the image smaller */}
        <img
          className="w-full h-full rounded-lg object-contain object-center" // Changed object-cover to object-contain
          src={S3_URL.concat(imageUrl)}
          alt={`${name}-candidate`}
        />
      </div>
      <div>
        <div className="mt-6 flex items-center justify-between px-4">
          <p className="font-bold text-gray-800">{name}</p>
        </div>
        <div className="mt-3 flex items-center justify-start gap-3 px-4">
          <p className="text-[1.5rem] text-gray-700">Campaign: </p>
          <p className="px-2 py-0.5 text-[1.25rem] font-semibold text-gray-600 overflow-clip">{campaign}</p>
        </div>
        <div className="mt-3 flex items-center justify-start gap-3 px-4">
          <p className="text-[1.5rem] text-gray-700">Stage: </p>
          <p className="px-2 py-0.5 text-[1.25rem] font-semibold text-gray-600 overflow-clip">{stage}</p>
        </div>
        <div className="mt-3 flex items-center justify-start gap-3 px-4">
          <p className="text-[1.5rem] text-gray-700">Nationality: </p>
          <p className="px-2 py-0.5 text-[1.25rem] font-semibold text-gray-600 overflow-clip">{nationality}</p>
        </div>
      </div>
    </div>
  );
}

export default CandidateCard;
