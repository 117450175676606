import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../../utils/axios';
import { CandiadateResponse } from '../../../slices/interface/votingcampaign/stage/getAllCandidate.interface';
interface Props {
    stageId: string
    xapi: string
    authToken: string
}
export const GetCandidatesByStageId = createAsyncThunk("/candidates/stageId", async (props: Props, thunkAPI) => {
    try {
        const response = await api.get<CandiadateResponse>(`/candidates/voting-campaigns-stages/${props.stageId}`, {
            headers: {
                "x-api-key": `Bearer ${props.xapi}`,
                "Authorization": `Bearer ${props.authToken}`,
            }
        })
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message)
    }
})