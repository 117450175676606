import TableTop from "../../../src/components/tabletop/Tabletop"
import Navbar from "../../../src/components/navbar/Navbar"
import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { dataService } from "../../utils/axios"
import { useAppDispatch } from "../../utils/redux.utils"
import { S3_URL } from "../../constants/constants"
import { Link, useNavigate, useParams } from "react-router-dom"
import { GetSponsersList } from "../../../src/redux/actions/sponsers/sponsersList.action"
import Loading from "../../../src/components/Loading/Loading"
import { DeleteSponsers } from "../../../src/redux/actions/sponsers/SponsersDelete.action"
import {
  dismissToast,
  errorToast,
  loadingToast,
  successToast,
} from "../../../src/utils/toastify"
import { resetState } from "../../../src/redux/slices/Sponers/DeleteSponsers.slices"

const Sponsers = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const didMount = useRef<boolean>(false)
  const dispatch = useAppDispatch()

  const { data: SponsersData, isPending } = useSelector(
    (state: RootStore) => state.sponsers,
  )

  const {
    isPending: isPendingDelete,
    isFulfilled: isFullfilledDelete,
    isRejected: isRejectedDelete,
  } = useSelector((state: RootStore) => state.delete_Sponser)

  const { data: AuthData } = useSelector((state: RootStore) => state.login)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true
      dataService.setApiKey(x_api!)
      dataService.setToken(AuthData.token!)
      dispatch(GetSponsersList())
    }
  }, [dispatch, x_api, AuthData.token])

  const deleteSponser = (id: string) => {
    dispatch(DeleteSponsers(id))
  }

  useEffect(() => {
    ;(isPendingDelete || isFullfilledDelete || isRejectedDelete) &&
      dismissToast()
    if (isRejectedDelete) {
      errorToast("Error Deleteing Sponser ! Net Problem ??")
      dispatch(resetState())
    }
    if (isPendingDelete) {
      loadingToast("deleting Sponser")
      dispatch(resetState())
    }
    if (isFullfilledDelete) {
      successToast("Sponser Deleted Successfully")
      dispatch(GetSponsersList())
      dispatch(resetState())
    }
  }, [isFullfilledDelete, isPendingDelete, isRejectedDelete])

  if (isPending) {
    return <Loading />
  }

  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Sponsers" showBars={true} />
        </div>
        <div className="table-wrapper-top">
          <TableTop back={false} />
        </div>
        <div className="w-full flex items-center justify-end pr-10 pb-5">
          <input
            type="text"
            placeholder="Search user"
            className="placeholder:text-medium pl-3 outline-none border-1 rounded-lg py-1 border-[#0b005a]"
            value={"search"}
            // onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="table-wrapper">
          <table className="table-wrapper-table" border={0}>
            <thead>
              <tr>
                <th>SN</th>
                <th>Title</th>
                <th>Contact</th>
                <th>Logo</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {SponsersData && SponsersData.length !== 0 ? (
                SponsersData.map((data: any, index: number) => (
                  <tr key={data.id}>
                    <td className="sn">{index + 1}</td>
                    <td>{data.title}</td>
                    <td>{data.contact}</td>
                    <td className="image">
                      <img src={S3_URL.concat(data.logo)} alt="pic" />
                    </td>
                    <td className="action">
                    <Link to={`edit/${data.id}`}>
                        <button className="bttn edit-btn">Edit</button>
                      </Link>
                      <button
                        onClick={() => deleteSponser(data.id)}
                        className="bttn delete-btn"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="notfound">
                    Data not found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Sponsers
