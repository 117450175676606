import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { InitialDashboardDataInterface } from "../../redux/slices/interface/dashboard.interface"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { useAppDispatch } from "../../utils/redux.utils"
import { dataService } from "../../utils/axios"
import { GetDashBoardData } from "../../redux/actions/Dashboard/dashboard.actions"
import { isExpired } from "../../utils/helper"
// import { getActiveVotingCampaigns } from "@/src/redux/actions/votingCampaign/stages/getCampaignStage.action"
import { getActiveVotingCampaigns } from "../../redux/actions/votingCampaign/stages/getCampaignStage.action"

const Counter: React.FC = () => {
  const { dashboardData, expiry }: Partial<InitialDashboardDataInterface> =
    useSelector((state: RootStore) => state.dashboard)
  const dispatch = useAppDispatch()
  const { x_api } = useSelector((state: RootStore) => state.x_api_key)
  const { data } = useSelector((state: RootStore) => state.login)
  const didMount = useRef(false)
  console.log(x_api)

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true

      if (x_api) {
        dataService.setApiKey(x_api)
      }

      if (data.token) {
        dataService.setToken(data.token)
      }

      if (x_api && (!dashboardData || isExpired(expiry))) {
        dispatch(GetDashBoardData())
        dispatch(getActiveVotingCampaigns({ xapi: x_api }))
      }
    }
  }, [x_api, data.token, dashboardData, expiry, dispatch])
  const allCampaigns = useSelector((state: RootStore) => state.allCampaigns)
  console.log(allCampaigns)
  return (
    <div className="dashboard">
      <div className="dashboard-analytics">
        <Link
          to="/user"
          className="dashboard-analytics--card bg-blue-600 text-white font-bold"
        >
          <div className="dashboard-analytics--card_icon">
            <i className="fa-regular fa-users"></i>
          </div>
          <h3 className="text-3xl">Users</h3>

          <h4 className="text-6xl">{dashboardData?.userCount ?? "00"}</h4>
        </Link>

        <Link
          to="/reports"
          className="dashboard-analytics--card bg-[#f58314] text-white font-bold"
        >
          <div className="dashboard-analytics--card_icon">
            <i className="fas fa-vote-yea"></i>
          </div>
          <h3 className="text-3xl">Voting Polls</h3>

          <h4 className="text-6xl">
            {dashboardData?.votingCampaignStageCount ?? "00"}
          </h4>
        </Link>

        <Link
          to="/votingcampaign"
          className="dashboard-analytics--card bg-gray-400 text-white font-bold"
        >
          <div className="dashboard-analytics--card_icon">
            <i className="fas fa-user"></i>
          </div>
          <h3 className="text-3xl">Contestants</h3>

          <h4 className="text-6xl">{dashboardData?.candidateCount ?? "00"}</h4>
        </Link>

        <Link
          to="/reports"
          className="dashboard-analytics--card bg-purple-900 text-white font-bold"
        >
          <div className="dashboard-analytics--card_icon">
            <i className="fa-regular fa-memo-circle-check"></i>
          </div>
          <h3 className="text-3xl">Voters</h3>

          <h4 className="text-6xl">0</h4>
        </Link>
        <Link
          to="/votingcampaign"
          className="dashboard-analytics--card bg-amber-800 text-white font-bold"
        >
          <div className="dashboard-analytics--card_icon">
            <i className="fa-regular fa-memo-circle-check"></i>
          </div>
          <h3 className="text-3xl">Campaign</h3>

          <h4 className="text-6xl">
            {allCampaigns?.activeCampaigns?.data?.count}
          </h4>
        </Link>
      </div>
    </div>
  )
}

export default Counter
