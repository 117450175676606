import { fileToBase64 } from "../../../../src/utils/fileToBase64";

interface Data {
  name: string;
  post: string;
  description: string;
  votingCampaignId: string;
  image: File | null | string;
  existingimage: string;
}

export const AddJudgeData = async (data: Data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("post", data.post);
  formData.append("description", data.description);
  formData.append("votingCampaignId", data.votingCampaignId);

  if (data.image && typeof data.image !== "string") {
    // Convert image to base64 string
    const base64Image = await fileToBase64(data.image);
    formData.append("image", base64Image);
  } else {
    formData.append("image", data.existingimage);
  }


  return formData;
};
