import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { CandiadateResponse, CandidateRows } from "../../redux/slices/interface/votingcampaign/stage/getAllCandidate.interface"
import { S3_URL } from "../../constants/constants"
import { BsFacebook, BsFillArrowLeftSquareFill, BsInstagram, BsTwitter } from "react-icons/bs"
import { TypedSelector, useAppDispatch } from "../../utils/redux.utils"
import { loginData } from "../../redux/slices/login/login.slices"
import { xApiKey } from "../../redux/slices/XapiKey/xApiKey.slice"
import { removeCandidate } from "../../redux/actions/votingCampaign/stages/removeCandidate.action"
import { useSelector } from "react-redux"
import { RootStore } from "../../redux/store"
import { addStageCandidate } from "../../redux/actions/votingCampaign/stages/candidate/addCandidate.action"
import { CandidateInterface } from "../../redux/slices/interface/candidates.interface"
import { FaPlus } from "react-icons/fa6"

const DataTable = ({ tableData, isLoading, isError, stageId }: { tableData: CandiadateResponse | null, isLoading: boolean, isError: boolean, stageId: string | undefined }) => {
  const navigate = useNavigate()
  const loginInfo = TypedSelector(loginData)
  const xapi = TypedSelector(xApiKey)
  const { data } = useSelector((state: RootStore) => state.get_all_candidates_by_campaign_id);
  const dispatch = useAppDispatch()
  const [shouldRender, setShouldRender] = useState<boolean>(false)
  const [unAddedCandidate, setUnAddedCandidate] = useState<CandidateInterface[]>([])
  const removeCandidateHandler = (id: string) => {
    if (loginInfo.data.token && xapi.x_api) {
      const data = {
        data: {
          candidateId: id,
          votingCampaignStageId: stageId as string
        },
        xapi: xapi.x_api,
        authToken: loginInfo.data.token
      }
      dispatch(removeCandidate(data))
    }
  }
  const addCandidate = (id: string) => {
    if (loginInfo.data.token && xapi.x_api) {
      const data = {
        data: {
          candidateId: id,
          votingCampaignStageId: stageId as string
        },
        xApiKey: xapi.x_api,
        authToken: loginInfo.data.token
      }
      dispatch(addStageCandidate(data))
    }
  }
  const filterCandidate = (campaignCandidate: CandidateInterface[], stageCandidate: CandidateRows[]) => {
    const unAdded = campaignCandidate.filter((candidate) =>
      !stageCandidate.some((stageCandi) => stageCandi.candidateId === candidate.id))
    setUnAddedCandidate(unAdded)
  }
  useEffect(() => {
    if (data && tableData?.data.rows)
      filterCandidate(data, tableData.data.rows)
  }, [data, tableData])
  return (
    <>
      <div className="table-wrapper-top">
        <Link
          to={".."}
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
        >
          <BsFillArrowLeftSquareFill className="icon" />
        </Link>
        <button
          className={`${!shouldRender ? "add-btn" : "border border-red-500 py-1 px-10 rounded-lg duration-200 text-red-500"}`}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
          onClick={() => setShouldRender(!shouldRender)}
        >
          {!shouldRender &&
            <span>
              <FaPlus />
            </span>
          }
          {shouldRender ? "Close" : "Add Entry"}
        </button>
      </div>
      {shouldRender &&
        <div className="bg-red-500 table-wrapper h-auto ">
          <table className="table-wrapper-table" border={0}>
            <thead>
              <tr>
                <th>SN</th>
                <th>Profile</th>
                <th>Name</th>
                <th>City</th>
                <th>Social</th>
                <th>nationality</th>
                <th>Action</th>
              </tr>
            </thead>
            {isLoading ?
              <h1>Fetching...</h1> :
              <tbody className="table-body">
                {(unAddedCandidate && unAddedCandidate.length > 0) ? (
                  unAddedCandidate?.map((data, index: number) => {
                    return (
                      <tr key={JSON.stringify(index)}>
                        <td className="sn">{index + 1}</td>
                        <td className="image ">
                          <img src={S3_URL.concat(data.profilePicture)} alt={data.name} />
                        </td>
                        <td>{data.name}</td>
                        <td>{data.city}</td>
                        <td className="flex items-center justify-center ">
                          <Link to={data.socialMediaInstagram} target="_blank">
                            <BsInstagram color="#e51c24" />
                          </Link>
                          <Link
                            to={data.socialMediaFacebook}
                            target="_blank"
                            className="ml-3"
                          >
                            <BsFacebook color="blue" />
                          </Link>
                          <Link
                            to={data.socialMediaTwitter}
                            target="_blank"
                            className="ml-3"
                          >
                            <BsTwitter />
                          </Link>
                        </td>
                        <td>{data.nationality}</td>
                        <td>
                          <button className="border px-10 py-2 text-xl bg-green-400 text-white border-black rounded-lg"
                            onClick={() => addCandidate(data.id)}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <h1>No candidate found.</h1>
                )}
              </tbody>
            }
          </table>
        </div>
      }
      <div className="table-wrapper">
        {!shouldRender &&
          <table className="table-wrapper-table" border={0}>
            <thead>
              <tr>
                <th>SN</th>
                <th>Profile</th>
                <th>Name</th>
                <th>City</th>
                <th>Social</th>
                <th>nationality</th>
                <th>Action</th>
              </tr>
            </thead>
            {isLoading ?
              <h1>Fetching...</h1> :
              <tbody className="table-body">
                {tableData ? (
                  tableData?.data.rows.map((data, index: number) => {
                    return (
                      <tr key={JSON.stringify(index)}>
                        <td className="sn">{index + 1}</td>
                        <td className="image ">
                          <img src={S3_URL.concat(data.candidate.profilePicture)} alt={data.candidate.name} />
                        </td>
                        <td>{data.candidate.name}</td>
                        <td>{data.candidate.city}</td>
                        <td className="flex items-center justify-center ">
                          <Link to={data.candidate.socialMediaInstagram} target="_blank">
                            <BsInstagram color="#e51c24" />
                          </Link>
                          <Link
                            to={data.candidate.socialMediaFacebook}
                            target="_blank"
                            className="ml-3"
                          >
                            <BsFacebook color="blue" />
                          </Link>
                          <Link
                            to={data.candidate.socialMediaTwitter}
                            target="_blank"
                            className="ml-3"
                          >
                            <BsTwitter />
                          </Link>
                        </td>
                        <td>{data.candidate.nationality}</td>
                        <td>
                          <button className="bttn delete-btn"
                            onClick={() => removeCandidateHandler(data.candidate.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <h1>Data not found.</h1>
                )}
              </tbody>
            }
          </table>
        }
      </div>
    </>
  )
}

export default DataTable
