import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { deleteCampaignById } from '../../actions/votingCampaign/deleteCampaignbyId.action';
import { RootStore } from '../../store';

interface InitialState {
    data: {
        message: string
        success: boolean
    } | null
    isLoading: boolean
    isError: boolean
}
const initialState: InitialState = {
    data: null,
    isLoading: false,
    isError: false
}
const deleteVotingCampaignSlice = createSlice({
    name: "@deleteVotingCampaign",
    initialState,
    reducers: {
        resetCampaign(state: InitialState) {
            state.data = null
            state.isError = false
            state.isLoading = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCampaignById.pending, (state: InitialState) => {
            state.isLoading = true
        }).addCase(deleteCampaignById.fulfilled, (state: InitialState, action: PayloadAction<any>) => {
            state.data = action.payload
        }).addCase(deleteCampaignById.rejected, (state: InitialState) => {
            state.isError = true
        })
    }
});
export const { resetCampaign } = deleteVotingCampaignSlice.actions
export const deleteCampaign = (state: RootStore) => state.delete_voting_campaign
export default deleteVotingCampaignSlice.reducer