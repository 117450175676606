import Navbar from "../../../components/navbar/Navbar"
import DataTable from "../../../components/datatable/Table"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { TypedSelector, useAppDispatch } from "../../../utils/redux.utils"
import { loginData } from "../../../redux/slices/login/login.slices"
import { xApiKey } from "../../../redux/slices/XapiKey/xApiKey.slice"
import { GetCandidatesByStageId } from "../../../redux/actions/votingCampaign/stages/getCandiadate.action"
import { stageCandidates } from "../../../redux/slices/votingCampaign/stage/getCandidates.slice"
export const VotingStageCandidate = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const loginInfo = TypedSelector(loginData)
  const xapi = TypedSelector(xApiKey)
  const candidates = TypedSelector(stageCandidates)
  useEffect(() => {
    if (loginInfo.data.token && xapi.x_api) {
      const args = {
        stageId: id as string,
        xapi: xapi.x_api,
        authToken: loginInfo.data.token
      }
      dispatch(GetCandidatesByStageId(args))
    }
  }, [loginInfo])
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Voting Stage Candidate" showBars />
        </div>
        <DataTable tableData={candidates.data}
          isLoading={candidates.isLoading}
          isError={candidates.isError}
          stageId={id}
        />
      </div>
    </div>
  )
}
