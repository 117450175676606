import { createAsyncThunk } from "@reduxjs/toolkit"
import { dataService } from "../../../utils/axios"

export const UpdateCoupon = createAsyncThunk(
  "voting-dashboad/update-coupon",
  async ({ ...restData }: any, thunkAPI): Promise<boolean | unknown> => {
    try {
      console.log(restData)

      const { id, avaibilityPeriodStart, avaibilityPeriodEnd, ...data } =
        restData
      const res = await dataService.putData(`/coupons/${id}`, { ...data }) // Use restData without id, avaibilityPeriodStart, avaibilityPeriodEnd
      return res.success;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
