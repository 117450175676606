import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetCandidatesByStageId } from '../../../actions/votingCampaign/stages/getCandiadate.action'
import { RootStore } from '../../../store'
import { CandiadateResponse } from '../../interface/votingcampaign/stage/getAllCandidate.interface'
export interface InitialCandidatesInterface {
    data: CandiadateResponse | null
    isLoading: boolean
    isError: boolean
}
const initialState: InitialCandidatesInterface = {
    data: null,
    isLoading: false,
    isError: false
}
const getCandidatesByStageId = createSlice({
    name: "@getCandiadate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetCandidatesByStageId.pending, (state: InitialCandidatesInterface) => {
            state.isLoading = true
        }).addCase(GetCandidatesByStageId.fulfilled, (state: InitialCandidatesInterface, action: PayloadAction<CandiadateResponse>) => {
            state.data = action.payload
            state.isLoading = false
        }).addCase(GetCandidatesByStageId.rejected, (state: InitialCandidatesInterface) => {
            state.isError = true
            state.isLoading = false
        })
    }
});
export const { } = getCandidatesByStageId.actions
export const stageCandidates = (state: RootStore) => state.get_candidates_by_stage_id
export default getCandidatesByStageId.reducer