import { PayloadAction, createSlice} from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { GetJudgeID } from "../../actions/Judges/JudgeGetOne.action";


interface Judge{
    image: string;
    name: string;
    description: string;
    votingCampaignId: string;
    post: string;
}
export interface initialJudgeByIdData {
  data : Judge | null
  isPending : boolean
  isFulfilled : boolean
  isRejected : boolean
}

const initialState : initialJudgeByIdData = {
  data : null,
  isFulfilled : false,
  isPending : false,
  isRejected : false,
}

export const GetJudgeSliceById = createSlice({
  name : '@voting-dashboard/getCouponByCampaignId',
  initialState,
  reducers : {},
  extraReducers : (builder) =>{
    builder
      .addCase(GetJudgeID.pending ,(state) => {
        state.isPending = true
        state.isRejected = false  
        state.isFulfilled = false
      } )
      .addCase(GetJudgeID.fulfilled ,(state , action : PayloadAction<Judge | undefined>) => {
        state.isPending = false
        state.isRejected = false
        state.isFulfilled = true

        // returning fulfilled response
        state.data = action.payload ?? null
      } )
      .addCase(GetJudgeID.rejected , (state) => {
        state.isPending = false
        state.isFulfilled = false
        state.isRejected = true
      })
  }
})

export const GetJudgeIdData = (state: RootStore) => state.judge_by_id
export default GetJudgeSliceById.reducer



