import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataService } from "../../../utils/axios";
import { initialVoteResponseInterface, VoteInterface } from "../../slices/interface/vote.interface";

export const GetAllVoteData = createAsyncThunk(
  '@voting-dashboard/voting-all-vote-data',
  async ( _ , thunkAPI : any) : Promise<VoteInterface[] | undefined> =>{
    try{
      const res : initialVoteResponseInterface = await dataService.getData('/votes')
      const filteredData  = res.data
      return filteredData?.rows
    }catch(error : any){
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)