import { createAsyncThunk } from "@reduxjs/toolkit"
import { CampaignUpdateResponse } from "../../slices/interface/votingcampaign/updateVCampaign.interface"
import { api } from "../../../utils/axios"

interface Props {
    id: string
    data: {
        logo: File
    }
}
export const updateCampaignLogo = createAsyncThunk(
    "deleteCampaign",
    async (props: Props, thunkAPI) => {
        try {
            const response = await api.patch<CampaignUpdateResponse>(
                `/voting-campaign/logo/${props.id}`, props.data)
            return response.data
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message)
        }
    },
)