import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import dayjs from "dayjs"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker"
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker"
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers"
import { useEffect, useState } from "react"
import { api } from "../../../utils/axios"
import Multiselect from "multiselect-react-dropdown"
import { FaSearch } from "react-icons/fa"
const FormSchema = z.object({
  dob: z.date({
    required_error: "A date of birth is required.",
  }),
})

const FilterVote = ({ setFilteredVoteValue }: any) => {
  const [keyword, setKeyword] = useState<string | null>(null)
  const [candidateId, setCandidateId] = useState<any>([]);
  const [stageID, setstageID] = useState<any>([])
  const [campaignID, setcampaignID] = useState<any>([])
  
  const [selectedCandidate, setSelectedCandidate] = useState<any[]>([])
  const [selectedStageID, setSelectedStageID] = useState<any[]>([])
  const [selectedCampaign, setSelectedCampaign] = useState<any[]>([])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if ((keyword !== null && keyword.length >= 0) || selectedStageID.length>0  || selectedCandidate.length>0 || selectedCampaign.length>0) {
        getVotes()
      } else {
        setFilteredVoteValue([])
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [keyword,selectedCandidate,selectedStageID,selectedCampaign])

 
  const getCandidates = async () => {
    try {
      const aa: any = await api.get(`/candidates`)
      const newArray = aa?.data?.data?.rows?.map((item:any) => {
        return { name: item.name, id: item.id };
      });
      setCandidateId(newArray)
    } catch (error) {
      setCandidateId([])
      console.log(error)
    }
  }

  const getStageID = async () => {
    try {
      const aa: any = await api.get(`/voting-campaign-stages`)
      const newArray = aa?.data?.data?.map((item:any) => {
        return { name: item.title, id: item.id };
      });
      setstageID(newArray)
    } catch (error) {
      setstageID([])
      console.log(error)
    }
  }

  const getCampaign = async () => {
    try {
      const aa: any = await api.get(`/voting-campaign`)
      const newArray = aa?.data?.data?.rows?.map((item:any) => {
        return { name: item.title, id: item.id };
      });
      setcampaignID(newArray)
    } catch (error) {
      setcampaignID([])
      console.log(error)
    }
  }

useEffect(()=>{
  getCandidates()
},[])
useEffect(()=>{
  getStageID()
  getCampaign()
},[])

  const getVotes = async () => {
    try {
      const email = keyword!==null && keyword.length>0 ? `&searchKeyword=${keyword}`:""
      const stageid = selectedStageID.length>0? `&votingCampaignStageId=${selectedStageID[0].id}`:""
      const candidateId = selectedCandidate.length>0? `&candidateId=${selectedCandidate[0].id}`:""
      const campaignId = selectedCampaign.length>0? `&votingCampaignId=${selectedCampaign[0].id}`:""
      const aa: any = await api.get(`/votes?${email}${campaignId}${stageid}${candidateId}`)
      setFilteredVoteValue(aa?.data?.data?.rows)
    } catch (error) {
      setFilteredVoteValue([])
      console.log(error)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (keyword !== null && keyword.length >= 1) {
        getVotes()
      } else {
        setFilteredVoteValue([])
      }
    }, 1000)
    return () => clearTimeout(timeout)
  }, [keyword])

  return (
    <section className="flex justify-end align-center gap-6">
      <div className="flex">
        {/* <p>Filter By</p> */}
        {/* <Select> */}
        {/*   <SelectTrigger */}
        {/*     className="w-[90px]" */}
        {/*     style={{ */}
        {/*       borderColor: "#BCBBBB", */}
        {/*       borderRadius: "5px", */}
        {/*       backgroundColor: "white", */}
        {/*     }} */}
        {/*   > */}
        {/*     <SelectValue placeholder="From Date" /> */}
        {/*   </SelectTrigger> */}
        {/*   <SelectContent> */}
        {/*     <SelectItem value="rating" defaultChecked> */}
        {/*       Rating */}
        {/*     </SelectItem> */}
        {/*     <SelectItem value="duration">Duration Time</SelectItem> */}
        {/*     <SelectItem value="price">Price</SelectItem> */}
        {/*   </SelectContent> */}
        {/* </Select> */}
      </div>
      {/* <p className="">9 Vehicle Found</p> */}
      <div className="flex flex-col gap-5 relative items-center w-full">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex justify-end gap-4 items-end w-full">
            <DateTimePicker
              label="From Date"
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "16px", // Input text font size
                  backgroundColor: "white",
                },
                "& .MuiFormLabel-root": {
                  fontSize: "16px", // Label font size
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "16px", // Input container font size
                  backgroundColor: "white",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px", // Icon font size
                  backgroundColor: "white",
                },
              }}
            />
            <DateTimePicker
              label="To Date"
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
              }}
              sx={{
                "& .MuiInputBase-input": {
                  fontSize: "16px", // Input text font size
                  backgroundColor: "white",
                },
                "& .MuiFormLabel-root": {
                  fontSize: "16px", // Label font size
                  backgroundColor: "white",
                },
                "& .MuiOutlinedInput-root": {
                  fontSize: "16px", // Input container font size
                  backgroundColor: "white",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px", // Icon font size
                  backgroundColor: "white",
                },
              }}
            />
          </div>
          <div className="flex justify-between items-center w-full">
            <input
              onChange={(e: any) => setKeyword(e.target.value)}
              type="text"
              placeholder="Search"
              className="placeholder:text-medium pl-3 outline-none border rounded-lg py-1 border-gray-300 border-solid h-[45px]"
            />

            <Multiselect
              options={campaignID.length>0?campaignID:[]}
              selectedValues={selectedCampaign}
              onSelect={(selectedList: any) => {setSelectedCampaign(selectedList)}}
              onRemove={(selectedList: any) => {setSelectedCampaign(selectedList)}}
              displayValue="name"
              placeholder="Select Campaign"
              className="bg-white"
              style={{
                container: { height: "60px" }, // Adjust container height
                multiselectContainer: { height: "45px", position: "relative" }, // Container height and position
                searchBox: {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }, // Center placeholder
                input: { height: "100%", textAlign: "center" }, // Center text inside input
                placeholder: { textAlign: "center" }, // Center placeholder text
              }}
            /> 

            <Multiselect
              options={candidateId.length>0?candidateId:[]}
              selectedValues={selectedCandidate}
              onSelect={(selectedList: any) => {setSelectedCandidate(selectedList)}}
              onRemove={(selectedList: any) => {setSelectedCandidate(selectedList)}}
              displayValue="name"
              placeholder="Select candidates"
              className="bg-white"
              style={{
                container: { height: "60px" }, // Adjust container height
                multiselectContainer: { height: "45px", position: "relative" }, // Container height and position
                searchBox: {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }, // Center placeholder
                input: { height: "100%", textAlign: "center" }, // Center text inside input
                placeholder: { textAlign: "center" }, // Center placeholder text
              }}
            /> 
           
            <Multiselect
              options={stageID.length>0?stageID:[]}
              selectedValues={selectedStageID}
              onSelect={(selectedList: any) => {setSelectedStageID(selectedList)}}
              onRemove={(selectedList: any) => {setSelectedStageID(selectedList)}}
              displayValue="name"
              placeholder="Select stage"
              className="bg-white z-50 "
              style={{
                container: { height: "55px" }, // Adjust container height
                multiselectContainer: { height: "45px" }, // Container height
                searchBox: {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px",
                }, // Center placeholder
                input: { height: "100%", textAlign: "center" }, // Center text inside input
                placeholder: { textAlign: "center" }, // Center placeholder text
              }}
            />
            <FaSearch className="" size={20} />
          </div>
        </LocalizationProvider>
      </div>
    </section>
  )
}

export default FilterVote
