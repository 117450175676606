import { PERSIST_KEY } from "../utils/config"
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist"
import { rootReducer } from "./rootReducer"



const persistConfig = {
  key: PERSIST_KEY,
  version: 2,
  storage,
  whitelist: ["systemState", "login", 'x_api_key', 'dashboard', 'get_all_user', 'news', "votingCampaign", "get_all_candidates_by_campaign_id", "stageByCampaginId",'get_all_vote','get_all_organization_settings','update_organization_setting'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export type RootStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
export default store
