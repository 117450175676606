import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GetAllVoteData } from "../../actions/vote/VoteData.actions";
import { RootStore } from "../../store";
import { addMinutesAndConvertToISOString } from "../../../utils/helper";
import { VoteInterface, VoteSliceInitialState } from "../interface/vote.interface";

const initialState : VoteSliceInitialState = {
  all_vote_data : {
    data : null,
    isPending : false,
    isRejected : false,
    isFulfilled : false,
    expiry : null
  }
}
export const VoteDataSlice = createSlice({
  name :'@voting-dashboard/votes',
  initialState,
  reducers : {},
  extraReducers : (builder) => {
    builder
    // Get all votes
      .addCase(GetAllVoteData.pending , (state) => {
        state.all_vote_data.isPending = true
        state.all_vote_data.isRejected = false
        state.all_vote_data.isFulfilled = false
      })
      .addCase(GetAllVoteData.fulfilled , (state , action : PayloadAction<VoteInterface[] | undefined>) => {
        state.all_vote_data.isPending = false
        state.all_vote_data.isRejected = action.payload ? false : true
        state.all_vote_data.isFulfilled = action.payload ? true : false
        state.all_vote_data.data = action.payload ?? null
        state.all_vote_data.expiry = action.payload ? addMinutesAndConvertToISOString(new Date(), 1) : null
      })
      .addCase(GetAllVoteData.rejected , (state) => {
        state.all_vote_data.isPending = false
        state.all_vote_data.isFulfilled = false
        state.all_vote_data.isRejected = true
      })
  }
})

export const VoteData = (state :RootStore) => state.get_all_vote
export default VoteDataSlice.reducer
