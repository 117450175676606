import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStore } from "../../store";
import { UpdateJudgeThunk } from "../../actions/Judges/JudgeUpdate.action";

interface UpdateJudgeState {
  isLoading: boolean;
  isRejected: boolean;
  isFulfilled: boolean;
  errorMessage: string;
}

const initialState: UpdateJudgeState = {
  isLoading: false,
  isRejected: false,
  isFulfilled: false,
  errorMessage: "",
};

export const UpdateJudgeSlice = createSlice({
  name: "@Voting-dashboard/updateJudge",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isFulfilled = false;
      state.isRejected = false;
      state.isLoading = false;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UpdateJudgeThunk.pending, (state) => {
        state.isLoading = true;
        state.isRejected = false;
        state.errorMessage = "";
      })
      .addCase(UpdateJudgeThunk.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload);
        state.isRejected = false;
        state.isFulfilled = true;
        state.isLoading = false;
      })
      .addCase(UpdateJudgeThunk.rejected, (state, action) => {
        console.log("reject");
        state.isLoading = false;
        state.isRejected = true;
        state.isFulfilled = false;
        state.errorMessage = action.error.message || "Something went wrong";
      });
  },
});

export const { resetState } = UpdateJudgeSlice.actions;
export const UpdateJudgeData = (state: RootStore) => state.update_judge_by_campiagnID;
export default UpdateJudgeSlice.reducer;
