import React, { ChangeEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RangeDatePicker } from "../../../components/date-picker/DatePicker"
import Navbar from "../../../components/navbar/Navbar"
import { createVCampaign } from "../../../redux/actions/votingCampaign/createVcampaign.action"
import { xApiKey } from "../../../redux/slices/XapiKey/xApiKey.slice"
import { loginData } from "../../../redux/slices/login/login.slices"
import {
  resetVC,
  votingCampaignCreationData,
} from "../../../redux/slices/votingCampaign/CreateVCamp.slice"
import { AppDispatch } from "../../../redux/store"
import PrevBtn from "../../../ui/prevbutton/BackBtn"
import { UIInput } from "../../../ui/uiinput/UIInput"
import { dataService } from "../../../utils/axios"
import { convertToIsoDateTime } from "../../../utils/helper"
import { TypedSelector } from "../../../utils/redux.utils"
import { errorToast, successToast } from "../../../utils/toastify"
import { CreateVotingCampaign } from "../../../utils/zod/campaign.schema"
export interface ImageValidation {
  logo: boolean
  banner: boolean
}
export interface VotingCampaighCreationState {
  logo: File | null
  title: string
  description: string
  banner: File | null
  startDateTime: string
  endDateTime: string
  timeZone: string
}
export const VotingCampaignAddEntry = () => {
  const [state, setState] = useState<VotingCampaighCreationState>({
    title: "",
    description: "",
    logo: null,
    banner: null,
    startDateTime: "",
    endDateTime: "",
    timeZone: "GMT",
  })
  const [isImageError, setIsImageError] = useState<ImageValidation>({
    logo: false,
    banner: false,
  })
  const [date, setDate] = useState<string>("")
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const loginInfo = TypedSelector(loginData)
  const xapiKey = TypedSelector(xApiKey)
  const votingCamp = TypedSelector(votingCampaignCreationData)
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    if (e.target instanceof HTMLInputElement && e.target.type === "file") {
      const files = e.target.files
      if (files && files.length > 0) {
        setState((prev) => ({ ...prev, [name]: files[0] }))
        return
      }
    }
    setState((prev) => ({ ...prev, [name]: value }))
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isImageError.logo || isImageError.banner) {
      errorToast("Logo and Banner should be less than 1mb")
      return
    }
    try {
      const finalState = CreateVotingCampaign.safeParse({
        ...state,
        startDateTime: convertToIsoDateTime(state.startDateTime),
        endDateTime: convertToIsoDateTime(state.endDateTime),
      })
      if (finalState.success) dispatch(createVCampaign(finalState.data))
      else errorToast(finalState.error.issues.at(0)?.message as string)
    } catch (error) {
      console.log(error)
    }
  }
  const handleStartDate = (e: string) => {
    setState((prev) => ({ ...prev, startDateTime: convertToIsoDateTime(e) }))
  }
  const handleEndDate = (e: string) => {
    setState((prev) => ({ ...prev, endDateTime: convertToIsoDateTime(e) }))
  }

  useEffect(() => {
    if (loginInfo.data.token && xapiKey.x_api) {
      dataService.setApiKey(xapiKey.x_api)
      dataService.setToken(loginInfo.data.token)
      dataService.setContentType("multipart/form-data")
    }
  }, [loginInfo, xapiKey])
  
  useEffect(() => {
    if (votingCamp.data?.success) {
      successToast("campaign created")
      navigate("/votingcampaign")
      dispatch(resetVC())
    }
    if (votingCamp.isError) {
      errorToast("Something went wrong")
      dispatch(resetVC())
    }
  }, [votingCamp])

  useEffect(() => {
    const logoSize = state.logo?.size
    const bannerSize = state.banner?.size
    if (logoSize)
      setIsImageError((prev) => ({
        ...prev,
        logo: (logoSize / (1000 * 1000))! > 1,
      }))
    if (bannerSize)
      setIsImageError((prev) => ({
        ...prev,
        banner: (bannerSize / (1000 * 1000))! > 1,
      }))
  }, [state.logo, state.banner])
  useEffect(() => {
    const date = new Date()
    setDate(date.toLocaleDateString())
    setState((prev) => ({ ...prev, startDateTime: date.toLocaleDateString() }))
  }, [])
  return (
    <div className="framecontainer">
      <div className="framecontainer-content">
        <div className="dashboard">
          <Navbar name="Add Entry - Voting Campaign" showBars />
        </div>
        <PrevBtn />
        <div className="form-wrapper">
          <form className="form" onSubmit={handleSubmit}>
            <div className="input">
              <UIInput
                value={state.title}
                onChange={handleChange}
                name="title"
                label="Title"
                type="text"
                placeholder="Enter your Title"
              />
            </div>
            <div
              className={`input ${isImageError.logo && "border border-red-500 px-3 rounded-xl"}`}
            >
              <label className="label">Logo</label>
              <input
                name="logo"
                type="file"
                onChange={handleChange}
                accept="image/*"
                className="block w-full text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                 file:font-semibold
                file:bg-[#0b005a]/80 file:text-white
                hover:file:bg-[#0b005a]
              "
              />
            </div>
            <div
              className={`input ${isImageError.banner && "border border-red-500 px-3 rounded-xl "} `}
            >
              <label className="label">Banner</label>
              <input
                name="banner"
                type="file"
                onChange={handleChange}
                accept="image/*"
                className="block w-full text-slate-500
      file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0
       file:font-semibold
      file:bg-[#0b005a]/80 file:text-white
      hover:file:bg-[#0b005a]
    "
              />
            </div>
            <div className="input">
              <label className="inputfield-label">StartDate</label>
              <RangeDatePicker
                value={state.startDateTime}
                minDate={date}
                onChange={handleStartDate}
                name="startDateTime"
              />
            </div>
            <div className="input">
              <label className="inputfield-label">EndDate</label>
              <RangeDatePicker
                value={state.endDateTime}
                minDate={date}
                onChange={handleEndDate}
                name="endDateTime"
              />
            </div>
            <div className="input textarea">
              <label className="label">Description</label>
              <textarea
                value={state.description}
                name="description"
                placeholder="Enter your description"
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="btn-wrapper  flex items-center justify-center">
              <button
                className={`${votingCamp.isLoading ? "bg-[#2c2375]" : "bg-[#0b005a]"}  py-2 px-36 text-white  btn btn-submit rounded-full duration-200`}
                disabled={votingCamp.isLoading}
              >
                {!votingCamp.isLoading ? "Submit" : "Creating campaign..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
