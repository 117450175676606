// In src/actions/organization/updateOrganizationSetting.action.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { VOTING_USER_BASE_URI } from '../../../utils/config';

export const UpdateOrganizationSettingThunk = createAsyncThunk(
  'organization-settings/updateOrganizationSetting',
  async ({ id, data }: { id: string, data: any }) => {
    const response = await axios.put(`${VOTING_USER_BASE_URI}/org-setting/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        "x-api-key": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdhbml6YXRpb25JZCI6ImQ4MmIzNjk0ZDlmOGNjNDkiLCJpc3MiOiJ2b3RpbmdfYXBwbGljYXRpb25fb3JnYW5pemF0aW9uX21hbmFnZW1lbnQiLCJpYXQiOjE3MjEyODE3MzAsImV4cCI6MTc4NDE4MjY1M30.RNv8kH8XYkx77DOsju6wVnJH3_i6scncnY4vNq_k-Bo`,
        "authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMzNGEyZWNmMzg4ODA5MDYiLCJlbWFpbCI6ImF5dXNoQHN1cHJlbWVpdHNvbHV0aW9ucy5jb20iLCJpc1ZlcmlmaWVkIjp0cnVlLCJ1c2VyQWdlbnQiOiJQb3N0bWFuUnVudGltZS83LjM3LjMiLCJpc3MiOiJ2b3RpbmdfYXBwbGljYXRpb25fb3JnYW5pemF0aW9uX21hbmFnZW1lbnQiLCJpYXQiOjE3MTQwNDQ4MDF9.G3mbCrXX4y1fuT7kXfSdvIPLCFYVHtyFsrP1hsPOEdw"
      }
    });

    if (response.status !== 200) {
      throw new Error('Failed to update organization setting');
    }

    return response.data;
  }
);
