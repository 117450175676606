import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GetOrganizationSettingsInitialInterface,
  OrganizationSettingsResponseInterface
} from "../interface/organizationSetting.interface";
import { GetAllOrganizationSettingsThunk } from "../../actions/organization/getAllOrganizationSetting.action";
import { RootStore } from "../../store";

const initialState: GetOrganizationSettingsInitialInterface = {
  isFulfilled: false,
  isLoading: false,
  isRejected: false,
  data: null
};

export const GetAllOrganizationSettingsSlice = createSlice({
  name: '@organization-settings/getAllOrganizationSettings',
  initialState,
  reducers: {
    resetState: (state) => {
      state.isFulfilled = false;
      state.isRejected = false;
      state.isLoading = false;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllOrganizationSettingsThunk.pending, (state) => {
        state.isLoading = true;
        state.isFulfilled = false;
        state.isRejected = false;
      })

      .addCase(GetAllOrganizationSettingsThunk.fulfilled, (state, action: PayloadAction<OrganizationSettingsResponseInterface | undefined>) => {
        state.isFulfilled = true;
        state.isLoading = false;
        state.isRejected = action.payload ? false : true;
        state.data = action.payload?.data || null;
      })

      .addCase(GetAllOrganizationSettingsThunk.rejected, (state) => {
        state.isLoading = false;
        state.isFulfilled = false;
        state.isRejected = true;
      });
  }
});

export const { resetState } = GetAllOrganizationSettingsSlice.actions;
export const GetAllOrganizationSettingsData = (state: RootStore) => state.get_all_organization_settings;
export default GetAllOrganizationSettingsSlice.reducer;
